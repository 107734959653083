const isLocalDevServer =
  process.env.NODE_ENV !== 'development'
    ? false
    : !/^remote$/.test(process.env.REACT_APP_DEV as string);

const protocol = isLocalDevServer ? 'http' : 'https';

const mainDomain = 'pracownikwterenie.pl';
const endpointBase = `${protocol}://sprzetendpoint.${mainDomain}`;
const assetsBase = `${protocol}://sprzetassets.${mainDomain}`;
export const endpointPhpAddress = isLocalDevServer
  ? `${protocol}://localhost:4444`
  : `${endpointBase}/web`;

// last used working version: 3.31
export const googleMapURL = `${protocol}://maps.googleapis.com/maps/api/js?v=weekly&libraries=places,geometry&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

export const netlifyFunctionsBase = `${window.location.origin}/.netlify/functions`;

export const photoAssetsAddress = `${assetsBase}/zdjecia`;
export const iconAssetsAddress = `${assetsBase}/images`;

export const getDistinctProjectNamesPhpAddress = `${netlifyFunctionsBase}/getDistinctProjectNames`;

export const getMarkersPhpAddress = `${netlifyFunctionsBase}/getDisplayProjectData`;
// TODO: complete backend
export const getConfigureUsersDataPhpAddress = `${netlifyFunctionsBase}/getConfigureUsersData`;

export const addUserPhpAddress = `${netlifyFunctionsBase}/addEmployee`;

export const getCsvConfigPhpAddress = `${netlifyFunctionsBase}/getCsvExportConfig`;
export const getPdfConfigPhpAddress = `${netlifyFunctionsBase}/getPdfExportConfig`;
export const importPointsPhpAddress = `${netlifyFunctionsBase}/importPoints`;
export const sendPointsPhpAddress = `${netlifyFunctionsBase}/editPoints`;
export const getUserProjectsDataPhpAddress = `${netlifyFunctionsBase}/getUsersAndProjects`;
export const sendUserProjectsPhpAddress = `${netlifyFunctionsBase}/updateUsersProjects`;
export const getCompanyEmployeesPhpAddress = `${netlifyFunctionsBase}/getCompanyEmployees`;
export const getPhotosPhpAdress = `${netlifyFunctionsBase}/getPointPhotos`;

export const editUserPhpAddress = `${endpointPhpAddress}/edituser.php`;
// export const editUserPhpAddress = `${netlifyFunctionsBase}/editUser`;

export const downloadCsvWthConfigPhpAddress = `${netlifyFunctionsBase}/generateProjectCsv`;
// export const downloadCsvWthConfigPhpAddress = `${netlifyFunctionsBase}/generate_project_csv_wth_config.php`;

// file generation
export const downloadPdfWthConfigPhpAddress = `${endpointPhpAddress}/generate_project_pdf_wth_config.php`;

// reports
export const getEmployeeStatisticsReportPhpAddress = `${endpointPhpAddress}/employee_statistics_report.php`;
export const getEmployeeDetailsReportPhpAddress = `${endpointPhpAddress}/employee_details_report.php`;
export const pointHistoryReportPhpAddress = `${endpointPhpAddress}/point_history_with_coordinates_report.php`;
export const getProjectAssetStateReportPhpAddress = `${endpointPhpAddress}/asset_state_report.php`;
export const getProjectAssetStateWithTypesReportPhpAddress = `${endpointPhpAddress}/asset_state_report_types.php`;
export const inactivityFor30DaysReportPhpAddress = `${endpointPhpAddress}/inactivity_for_30_days_report.php`;
export const inventoryAsOfDayReportPhpAddress = `${endpointPhpAddress}/inventory_as_of_day_report.php`;
export const getEmployeeActivityReportDataPhpAddress = `${endpointPhpAddress}/get_employee_activity_report_data.php`;
export const createProjectActivityPhpAddress = (projectId: string) =>
  `${endpointPhpAddress}/projectactivity.php?projectid=${projectId}`;

export const createProjectAssetStateReportPhpAddress = (projectId: string) =>
  `${endpointPhpAddress}/assetstatereport.php?projectid=${projectId}`;

// random to confirm usefullness
export const liquidatePointPhpAddress = `${endpointPhpAddress}/liquidate_point.php`;
