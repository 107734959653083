import { rest, context, response } from 'msw';
import * as fixtures from './fixtures';

const netlifyFunctionsBase = '/.netlify/functions';
const devApiBase = 'http://localhost:4444';

const id = <T>(a: T) => a;

export const createHandlers = (delay: boolean) => {
  const res = (...handlers: Parameters<typeof response>) =>
    response(...handlers, delay ? context.delay() : id);

  return [
    rest.post(`${netlifyFunctionsBase}/login`, (req, _, ctx) => {
      return res(ctx.json(fixtures.login()));
    }),
    rest.get(`${netlifyFunctionsBase}/getUserData`, (req, _, ctx) => {
      return res(ctx.json(fixtures.login()));
    }),
    rest.post(
      `${netlifyFunctionsBase}/getDistinctProjectNames`,
      (req, _, ctx) => {
        return res(ctx.json(['projekt1', 'projekt2']));
      },
    ),
    rest.get(
      `${netlifyFunctionsBase}/getProjectCreationConfig`,
      (req, _, ctx) => {
        return res(ctx.json(fixtures.getProjectCreationConfig()));
      },
    ),
    rest.get(`${netlifyFunctionsBase}/addEmployee`, (req, _, ctx) => {
      return res(ctx.json({ new_id: 10 }));
    }),
    rest.post(`${netlifyFunctionsBase}/createProject`, (req, _, ctx) => {
      return res(
        ctx.json({
          success: true,
          projectId: 42,
        }),
      );
    }),

    rest.get(`${netlifyFunctionsBase}/getDisplayProjectData`, (req, _, ctx) => {
      return res(ctx.json(fixtures.getMarkers()));
    }),
    rest.post(`${netlifyFunctionsBase}/getPdfReportData`, (req, _, ctx) => {
      return res(ctx.json(fixtures.getPdfReportData()));
    }),
    rest.get(`${devApiBase}/getmarkers1.php`, (req, _, ctx) => {
      return res(ctx.json(fixtures.getMarkers()));
    }),

    rest.post(`${devApiBase}/getphotos.php`, (req, _, ctx) => {
      return res(ctx.json(fixtures.getPhotos()));
    }),
    rest.get(`${netlifyFunctionsBase}/getPointPhotos`, (req, _, ctx) => {
      return res(ctx.json(fixtures.getPhotos()));
    }),

    rest.post(`${devApiBase}/get_csv_config.php`, (req, _, ctx) => {
      return res(ctx.json(fixtures.getCsvConfig()));
    }),
    rest.get(`${netlifyFunctionsBase}/getCsvExportConfig`, (req, _, ctx) => {
      return res(ctx.json(fixtures.getCsvConfig()));
    }),

    rest.post(`${devApiBase}/get_pdf_config.php`, (req, _, ctx) => {
      return res(ctx.json(fixtures.getPdfConfig()));
    }),
    rest.get(`${netlifyFunctionsBase}/getPdfExportConfig`, (req, _, ctx) => {
      return res(ctx.json(fixtures.getPdfConfig()));
    }),

    rest.post(`${devApiBase}/addproject.php`, (req, _, ctx) => {
      return res(
        ctx.json({
          success: true,
          projectId: 42,
        }),
      );
    }),
    rest.post(`${devApiBase}/get_user_projects_data.php`, (req, _, ctx) => {
      return res(ctx.json(fixtures.getUserProjectsData()));
    }),
    rest.post(
      `${devApiBase}/edit_user_projects_configuration.php`,
      (req, _, ctx) => {
        return res(ctx.json({}));
      },
    ),
    rest.post(`${devApiBase}/get_configure_users_data1.php`, (req, _, ctx) => {
      return res(ctx.json(fixtures.getConfigureUsersData()));
    }),
    rest.post(`${devApiBase}/employee_statistics_report.php`, (req, _, ctx) => {
      return res(ctx.json(fixtures.employeeStatisticsReport()));
    }),
    rest.post(`${devApiBase}/get_company_employees.php`, (req, _, ctx) => {
      return res(ctx.json(fixtures.getCompanyEmployees()));
    }),
    // rest.post(`${netlifyFunctionsBase}/generateProjectCsv`, (req, _, ctx) => {
    //   return res(ctx.json(fixtures.getCompanyEmployees()));
    // }),
  ];
};
