export const fixture = () => ({
  users: [
    {
      ID: '71',
      LOGIN: 'braciaadmin',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1002',
      LOGIN: 'pracownik1',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1007',
      LOGIN: 'pracownik2',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1008',
      LOGIN: 'test',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1009',
      LOGIN: 'pawel.kujawski',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1010',
      LOGIN: 'rafal.zolniezak',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '20',
      UPR_PANEL: '20',
    },
    {
      ID: '1011',
      LOGIN: 'sebastian.czajka',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1012',
      LOGIN: 'mateusz.wroblewski',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1013',
      LOGIN: 'tomasz.drukalski',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1014',
      LOGIN: 'lukasz.podgorski',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1015',
      LOGIN: 'rafal.dzieciol',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1016',
      LOGIN: 'waldemar.krzymowski',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1017',
      LOGIN: 'przemyslaw.zygmunt',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1018',
      LOGIN: 'piotr.janowski',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1019',
      LOGIN: 'roman.terechowicz',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1020',
      LOGIN: 'przemyslaw.salasinski',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1021',
      LOGIN: 'przemyslaw.sobieraj',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1022',
      LOGIN: 'arkadiusz.zawistowski',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1023',
      LOGIN: 'jacek.odziemczyk',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1024',
      LOGIN: 'igor.podzihun',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1025',
      LOGIN: 'marek.durzynski',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1026',
      LOGIN: 'dariusz.opalinski',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1027',
      LOGIN: 'SZCZEPAN',
      ID_FIRMA: '7',
      STATUS: '0',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1028',
      LOGIN: 'szczepan.goszczynski',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1029',
      LOGIN: 'KIEROWCA',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1030',
      LOGIN: 'olga.rebelska',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1031',
      LOGIN: 'biuro.mobilne',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1032',
      LOGIN: 'PRZEMEK',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1033',
      LOGIN: 't1',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1034',
      LOGIN: 'HUBERT.KLEJNA',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1039',
      LOGIN: 'maciej.staniczykowski',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1040',
      LOGIN: 'LUKASZ.BOGUCKI',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1041',
      LOGIN: 'stanislaw.frelek',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1042',
      LOGIN: 'DENYS',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1043',
      LOGIN: 'dima',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1046',
      LOGIN: 'admin1',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1048',
      LOGIN: 'admin11',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1050',
      LOGIN: 'admin111',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '20',
    },
    {
      ID: '1052',
      LOGIN: 'admin1111',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '0',
    },
    {
      ID: '1053',
      LOGIN: 'admin11111',
      ID_FIRMA: '7',
      STATUS: '1',
      UPR_MOBILNE: '0',
      UPR_PANEL: '0',
    },
  ],
  panelStatuses: [
    { KOD: '0', OPIS: 'Brak dostępu (blokada poprzez komputer)' },
    { KOD: '20', OPIS: 'Full Dostęp do przydzielonych projektów.' },
    { KOD: '40', OPIS: 'Full  Dostęp do wszystkich projektów w firmie' },
    {
      KOD: '50',
      OPIS: 'Możliwość zakładania nowych projektów + import punków',
    },
  ],
  mobileStatuses: [
    { KOD: '0', OPIS: 'Brak dostępu (blokada poprzez telefon)' },
    { KOD: '20', OPIS: 'Full Dostęp do przydzielonych projektów.' },
    { KOD: '40', OPIS: 'Full dostęp do wszystkich projektów' },
  ],
});
