export const fixture = () => ({
  __meta: { region: 'eu-west-1' },
  data: {
    user: {
      ID: 1001,
      UPR_PANEL: 99,
      LOGIN: 'admin',
      NAZWA_FIRMY: 'NFC2',
      ID_FIRMA: 7,
    },
    projects: [
      {
        ID: 200,
        NAZWA: '== T E S T  ==',
        DODAWANIE_PUNKTOW: true,
        ID_STATUS: 1012,
      },
      { ID: 201, NAZWA: 'smietnik', DODAWANIE_PUNKTOW: true, ID_STATUS: null },
      {
        ID: 1000,
        NAZWA: '1.Toalety',
        DODAWANIE_PUNKTOW: true,
        ID_STATUS: 1000,
      },
      {
        ID: 1002,
        NAZWA: '2.Kontenery',
        DODAWANIE_PUNKTOW: true,
        ID_STATUS: 1000,
      },
      {
        ID: 1003,
        NAZWA: '3. Toalety usuniete',
        DODAWANIE_PUNKTOW: true,
        ID_STATUS: 1013,
      },
      { ID: 2, NAZWA: 'pr2', DODAWANIE_PUNKTOW: true, ID_STATUS: null },
      {
        ID: 3,
        NAZWA: 'zdsfsfdsfsdfsfd',
        DODAWANIE_PUNKTOW: true,
        ID_STATUS: null,
      },
      {
        ID: 4,
        NAZWA: 'zdsfsfdsfsdfsfdasdfasdf',
        DODAWANIE_PUNKTOW: true,
        ID_STATUS: null,
      },
      { ID: 5, NAZWA: 'projekt3', DODAWANIE_PUNKTOW: false, ID_STATUS: 1000 },
      { ID: 6, NAZWA: 'projekt4', DODAWANIE_PUNKTOW: true, ID_STATUS: 1000 },
    ],
  },
});
