import { Route, Routes } from 'react-router-dom';
import { lazy, Suspense } from 'react';

import './styles/App.css';

import LoginPanel from './LoginPanel';
import { FullPageSpinner } from './components/FullPageSpinner';
import { useAppSelector } from './hooks';
import { selectCurrentUser } from './authSlice';

const ProjectCreationMainScreen = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "project-creation-main-screen" */ './ProjectCreation/Components/ProjectCreationMainScreen'
    ),
);

const ViewProjectMainScreen = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "view-project-main-screen" */ './ViewProject/Components/ViewProjectMainScreen'
    ),
);

const ConfigureProjectComponent = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "configure-project-component" */ './ProjectConfiguration/Components/ConfigureProjectComponent'
    ),
);

const ManageUsersMainComponent = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "manage-users-main-component" */ './UserManagement/ManageUsersMainComponent'
    ),
);

const ReportsMain = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "reports-main" */ './Reports/Components/ReportsMain'
    ),
);

type AppProps = {};

export const App = (props: AppProps) => {
  const user = useAppSelector(selectCurrentUser);

  const loggedIn = Boolean(user);

  return (
    <div className="App">
      {process.env.NODE_ENV === 'development' && (
        <h1
          style={{
            background: 'red',
            color: '#fff',
            position: 'fixed',
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            margin: 0,
            padding: '10px 5px',
            zIndex: 9999,
          }}
        >
          Wersja testowa
        </h1>
      )}
      {loggedIn ? (
        <Suspense fallback={<FullPageSpinner />}>
          <Routes>
            <Route path="*" element={<ViewProjectMainScreen />} />
            <Route path="/login-success" element={<ViewProjectMainScreen />} />
            <Route
              path="/createproject/*"
              element={<ProjectCreationMainScreen />}
            />
            <Route
              path="/projectconfig"
              element={<ConfigureProjectComponent />}
            />
            <Route
              path="/usermanagement/*"
              element={<ManageUsersMainComponent baseUrl="/usermanagement" />}
            />
            <Route path="/reports/*" element={<ReportsMain />} />
          </Routes>
        </Suspense>
      ) : (
        <LoginPanel />
      )}
    </div>
  );
};
