export const fixture = () => ({
  __meta: { region: 'eu-west-1' },
  data: {
    statuses: [
      { ID: 2, NAZWA: 'status 1' },
      { ID: 11, NAZWA: 'stakjkjkjkjtus 1' },
      { ID: 1000, NAZWA: 'Na bazie' },
      { ID: 1002, NAZWA: 'Podstawienie' },
      { ID: 1004, NAZWA: 'Uszkodzony' },
      { ID: 1006, NAZWA: 'Pobranie z Bazy' },
      { ID: 1007, NAZWA: 'w Porcie' },
      { ID: 1008, NAZWA: 'Podstawienie.' },
      { ID: 1009, NAZWA: 'Odbior' },
      { ID: 1010, NAZWA: 'Serwis' },
    ],
    attributes: [
      { ID: 2, NAZWA: 'atrybut 1' },
      { ID: 11, NAZWA: 'atrybmmnkkmjut 1' },
      { ID: 123, NAZWA: 'WWWW' },
      { ID: 10000, NAZWA: 'Rodzaj' },
      { ID: 10001, NAZWA: 'Uwagi' },
      { ID: 10002, NAZWA: 'Wybierz rok' },
      { ID: 10003, NAZWA: 'Wykonana usługa' },
      { ID: 10004, NAZWA: 'Powod usunięcia' },
      { ID: 10005, NAZWA: 'Rodzaj sprzętu' },
      { ID: 10006, NAZWA: 'Dział' },
    ],
  },
});
