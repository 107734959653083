export const fixture = () => ({
  data: {
    rows: [],
    columns: [
      'PRACOWNIK',
      'INWENTARYZACJA',
      'PODSTAWIENIA',
      'ODBIOR',
      'NA_BAZIE',
      'SERWIS',
      'POBRANIE_Z_BAZY',
      'WSZYSTKICH_SERWISOW',
      'LACZNIE',
    ],
  },
  status: 'ok',
});
