import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import {
  AttributeDescriptor,
  StatusDescriptor,
} from '../functions/getDisplayProjectData';
import { RootState } from './store';
import { UserData, SuccessApiResponse } from './types';
import { netlifyFunctionsBase } from './Values/generalValues';
import statusesList from './Values/statusesList';

type ProjectConfigOptions = {
  statuses: any[];
  attributes: any[];
};

export type Status = StatusDescriptor;

type Attribute = AttributeDescriptor;

type Employee = {
  ID_FIRMA: string;
  NAZWA_FIRMY: string;
  STATUS: string;
  NAZWA: null | string;
  ID: string;
  LOGIN: string;
  UPR_PANEL: string;
};

type Photo = {
  thumbnail: string;
  fullSize: string;
};

export const isFetchBaseQueryErrorType = (
  error: any,
): error is FetchBaseQueryError => error && 'data' in error;

export const netlifyApi = createApi({
  reducerPath: 'appApi',
  baseQuery: fetchBaseQuery({
    baseUrl: netlifyFunctionsBase,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.authToken;

      if (!token) {
        throw Error('user not authenticated');
      }

      headers.set('Authorization', `Bearer ${token}`);

      return headers;
    },
  }),
  tagTypes: ['UserData'],
  endpoints: (builder) => {
    const queries = {
      getCurrentUserData: builder.query({
        query: () => ({
          url: 'getUserData',
        }),
        transformResponse: (response: {
          data: { projects: any[]; user: any };
        }) => {
          const {
            data: { projects, user },
          } = response;

          return {
            projects: projects.map(({ ID, ...project }) => ({
              ...project,
              ID: String(ID),
            })),
            user,
          } as const;
        },
      }),
      getDistinctProjectNames: builder.query<
        SuccessApiResponse<string[]>,
        string
      >({
        query: (companyId) => ({
          url: 'getDistinctProjectNames',
          method: 'POST',
          body: { companyId },
        }),
      }),
      getProjectCreationConfig: builder.query<
        SuccessApiResponse<ProjectConfigOptions>,
        undefined
      >({
        query: () => ({
          url: 'getProjectCreationConfig',
        }),
      }),
      getProjectDisplayData: builder.query<
        {
          statuses: Status[];
          attributes: Attribute[];
          points: string[][];
          options: unknown[];
        },
        string
      >({
        query: (projectId: string) => ({
          url: 'getDisplayProjectData',
          params: { projectId },
        }),
        transformResponse: (
          response: SuccessApiResponse<{
            statuses: Status[];
            attributes: Attribute[];
            points: string[][];
            options: unknown[];
          }>,
        ) => response.data,
      }),
      getPdfReportData: builder.query<
        {
          statuses: Status[];
          attributes: Attribute[];
          points: string[][];
          options: unknown[];
          photos: {
            pointId: number;
            pictureUrl: string;
          }[];
        },
        {
          projectId: string;
          requestData?: {};
        }
      >({
        query: ({ projectId, requestData = {} }) => ({
          url: 'getPdfReportData',
          body: { projectId, ...requestData },
          method: 'post',
        }),
        transformResponse: (
          response: SuccessApiResponse<{
            statuses: Status[];
            attributes: Attribute[];
            points: string[][];
            options: unknown[];
            photos: {
              pointId: number;
              pictureUrl: string;
            }[];
          }>,
        ) => response.data,
      }),
      getConfigureUsersData: builder.query<
        {
          users: any[];
          mobileStatuses: any[];
          panelStatuses: any[];
        },
        string
      >({
        query: (companyId: string) => ({
          url: 'getProjectCreationConfig',
          params: { companyId },
        }),
        transformResponse: (
          response: SuccessApiResponse<{
            users: any[];
            mobileStatuses: any[];
            panelStatuses: any[];
          }>,
        ) => {
          const {
            data: { mobileStatuses, panelStatuses, users },
          } = response;
          return {
            users: users.map((user) => ({
              ...user,
              mobileStatus: mobileStatuses.find(
                (status: any) => status.KOD === user.UPR_MOBILNE,
              ),
              panelStatus: panelStatuses.find(
                (status: any) => status.KOD === user.UPR_PANEL,
              ),
              statusName: statusesList.find(
                (s: any) => s.value.toString() === user.STATUS,
              )!.description,
            })),
            mobileStatuses: mobileStatuses.map((status: any) => ({
              ...status,
              OPIS: status.OPIS.replace(/\n/g, '').trim(),
            })),
            panelStatuses: panelStatuses.map((status: any) => ({
              ...status,
              OPIS: status.OPIS.replace(/\n/g, '').trim(),
            })),
          };
        },
      }),
      getCsvExportConfig: builder.query<
        SuccessApiResponse<{
          statuses: { id: string; name: string }[];
          lines: string[];
        }>,
        string
      >({
        query: (projectId: string) => ({
          url: 'getCsvExportConfig',
          params: { projectId },
        }),
      }),
      getPdfExportConfig: builder.query<
        SuccessApiResponse<{
          statuses: { id: string; name: string }[];
          lines: string[];
        }>,
        string
      >({
        query: (projectId: string) => ({
          url: 'getPdfExportConfig',
          params: { projectId },
        }),
      }),
      getUsersAndProjects: builder.query<
        SuccessApiResponse<{
          projects: any[];
          users: any[];
        }>,
        UserData
      >({
        query: (args) => ({
          url: 'getUsersAndProjects',
          body: args,
          method: 'post',
        }),
      }),
      getCompanyEmployees: builder.query<
        SuccessApiResponse<{
          employees: Employee[];
        }>,
        string
      >({
        query: (companyId) => ({
          url: 'getCompanyEmployees',
          body: { companyId },
          method: 'post',
        }),
      }),
      getPointPhotos: builder.query<
        SuccessApiResponse<{
          photos: Photo[];
        }>,
        string
      >({
        query: (pointId) => ({
          url: 'getPointPhotos',
          params: { pointId },
        }),
      }),
    };

    const mutations = {
      updateUsersProjects: builder.mutation<
        SuccessApiResponse,
        {
          userId: any;
          userProjectsToAdd: any[];
          userProjectsToDelete: any;
        }
      >({
        query: (args) => ({
          url: 'updateUsersProjects',
          method: 'post',
          body: args,
        }),
      }),
      importPoints: builder.mutation<
        SuccessApiResponse,
        {
          content: string;
          filename: string;
        }
      >({
        query: ({ content, filename }) => ({
          url: 'addEmployee',
          method: 'post',
          body: content,
          headers: {
            'Content-Disposition': `form-data; name="plik"; filename=${filename}`,
            'Content-Type': 'text/csv',
          },
        }),
      }),
      editPoints: builder.mutation<
        SuccessApiResponse,
        {
          INFO: {
            LAST_EDITED: string;
            LAST_SYNCHRONISED: string;
            ID_PROJEKT: any;
            ID_USER: any;
            ID_PUNKT: any;
            ID_STATUS: any;
            PRZESLO: any;
            internalId: string;
          };
          PUNKTCECHA: any;
        }[]
      >({
        query: (args) => ({
          url: 'editPoints',
          method: 'post',
          body: args,
        }),
      }),
      addUser: builder.mutation<
        SuccessApiResponse,
        {
          login: string;
          password: string;
          status: string;
          id_firma: number;
        }
      >({
        query: (args) => ({
          url: 'addEmployee',
          method: 'post',
          body: args,
        }),
      }),
      createProject: builder.mutation<
        SuccessApiResponse,
        {
          statusy: any[];
          atrybuty: any[];
          metadane: any;
        }
      >({
        query: (args) => ({
          url: 'createProject',
          method: 'post',
          body: args,
        }),
      }),
    };

    return { ...queries, ...mutations };
  },
});

export const {
  useLazyGetCurrentUserDataQuery,
  useLazyGetDistinctProjectNamesQuery,
  useCreateProjectMutation,
  useGetProjectCreationConfigQuery,
  useGetConfigureUsersDataQuery,
  useGetProjectDisplayDataQuery,
  useLazyGetPdfReportDataQuery,
  useGetPdfExportConfigQuery,
} = netlifyApi;
