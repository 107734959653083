import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { UserData } from './types';

const initialState = {
  user: null as UserData | null,
  authToken: null as string | null,
};

export type AuthState = typeof initialState;

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser: (
      state,
      { payload: { user } }: PayloadAction<{ user: UserData }>,
    ) => {
      state.user = user;
    },
    setAuthToken: (
      state,
      { payload: { token } }: PayloadAction<{ token: string }>,
    ) => {
      state.authToken = token;
    },
  },
});

export const { setCurrentUser, setAuthToken } = authSlice.actions;

export const selectCurrentUser = (state: RootState): UserData | null =>
  state.auth.user;

export const selectAuthToken = (state: RootState) => state.auth.authToken;
