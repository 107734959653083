export const fixture = () => ({
  __meta: { region: 'us-east-1' },
  data: {
    statuses: [
      { id: '1000', color: 3, position: 1, name: 'Na bazie' },
      { id: '1002', color: 4, position: 2, name: 'Podstawienie' },
      { id: '1006', color: 12, position: 3, name: 'Pobranie z Bazy' },
      { id: '1009', color: 4, position: 4, name: 'Odbior' },
      { id: '1004', color: 0, position: 5, name: 'Uszkodzony' },
    ],
    attributes: [
      {
        name: 'id',
        editable: false,
        id: '-1',
        display: false,
        displayName: 'id',
        type: 'T',
        visibleInWindow: false,
      },
      {
        name: 'statusId',
        editable: true,
        id: '-1',
        display: false,
        displayName: 'statusId',
        type: 'S',
        visibleInWindow: false,
      },
      {
        name: 'latitude',
        editable: false,
        id: '-1',
        display: false,
        displayName: 'ws1',
        type: 'T',
        visibleInWindow: false,
      },
      {
        name: 'longitude',
        editable: false,
        id: '-1',
        display: true,
        displayName: 'ws2',
        type: 'T',
        visibleInWindow: false,
      },
      {
        name: 'nfcTagUid',
        editable: false,
        id: '-1',
        display: true,
        displayName: 'Numer NFC',
        type: 'T',
        visibleInWindow: false,
      },
      {
        name: 'title',
        editable: false,
        id: '-1',
        display: true,
        displayName: 'Numer ew.',
        type: 'T',
        visibleInWindow: true,
      },
      {
        id: '10000',
        name: 'Rodzaj',
        editable: true,
        type: 'OWO',
        visibleInWindow: false,
        displayName: 'Rodzaj',
        display: true,
      },
      {
        id: '10003',
        name: 'Wykonana usługa',
        editable: true,
        type: 'EO',
        visibleInWindow: false,
        displayName: 'Wykonana usługa',
        display: true,
      },
      {
        id: '10001',
        name: 'Uwagi',
        editable: true,
        type: 'MT',
        visibleInWindow: false,
        displayName: 'Uwagi',
        display: true,
      },
    ],
    options: [
      { id: 100010, value: 'Kontener KP-5', attributeId: 10000, index: 1 },
      { id: 100011, value: 'Kontener KP-7', attributeId: 10000, index: 2 },
      { id: 100015, value: 'Kontener KP-16', attributeId: 10000, index: 3 },
      { id: 100029, value: 'Serwis', attributeId: 10003, index: 1 },
      { id: 100030, value: 'Naprawa', attributeId: 10003, index: 2 },
    ],
    points: [
      [
        '1000436',
        '1002',
        52.305483,
        20.9991933,
        '0404EEF2304C81',
        '135',
        '',
        '',
        '',
        1010,
      ],
      [
        '1000666',
        '1000',
        52.3056544,
        20.9992427,
        '0418E4F2304C81',
        '04',
        '',
        '',
        '',
        1004,
      ],
      [
        '1000667',
        '1000',
        52.3055504,
        20.9991617,
        '04EEF2F2304C80',
        '170',
        '',
        '',
        '',
        1004,
      ],
      [
        '1000668',
        '1000',
        52.3052769,
        20.9994367,
        '04F0EEF2304C80',
        '01',
        '',
        '',
        '',
        1004,
      ],
      [
        '1000669',
        '1000',
        52.3055995,
        20.9993984,
        '047DEEF2304C80',
        '13',
        '',
        '',
        '',
        1004,
      ],
      [
        '1000670',
        '1000',
        52.305616,
        20.9992564,
        '046CE8F2304C80',
        '156',
        '',
        '',
        '',
        1004,
      ],
      [
        '1000671',
        '1002',
        52.3056025,
        20.9992469,
        '04FAF2F2304C80',
        '12',
        '',
        '',
        '',
        1004,
      ],
      [
        '1000672',
        '1000',
        52.3047021,
        21.001135,
        '0432E8F2304C81',
        '15',
        '',
        '',
        '',
        1004,
      ],
      [
        '1000673',
        '1000',
        52.3056651,
        20.9995514,
        '045DE5F2304C81',
        '173',
        '',
        '',
        '',
        1004,
      ],
      [
        '1000674',
        '1000',
        52.3057102,
        20.9991512,
        '04A607DAA64A84',
        '186',
        '',
        '',
        '',
        1004,
      ],
      [
        '1000675',
        '1000',
        52.3058139,
        20.9991027,
        '04FBF2F2304C80',
        '141',
        '',
        '',
        '',
        1004,
      ],
      [
        '1000676',
        '1000',
        52.3057427,
        20.9990556,
        '04E9ECDAA64A80',
        '19',
        '',
        '',
        '',
        1004,
      ],
      [
        '1000677',
        '1000',
        52.3057148,
        20.99921,
        '04F2F6F2304C80',
        '08',
        '',
        '',
        '',
        1004,
      ],
      [
        '1000678',
        '1000',
        52.3055481,
        20.999187,
        '04BA0FDAA64A84',
        '125',
        '',
        '',
        '',
        1004,
      ],
      [
        '1000681',
        '1004',
        52.3055966,
        20.9996217,
        '04A4EDDAA64A80',
        '157',
        '',
        '',
        '',
        1003,
      ],
      [
        '1000691',
        '1000',
        52.3056129,
        20.9992536,
        '04D7F2F2304C80',
        '206',
        '',
        '',
        '',
        1003,
      ],
      [
        '1000692',
        '1000',
        52.3057128,
        20.9992695,
        '04BD07DAA64A84',
        '225',
        '',
        '',
        '',
        1003,
      ],
      [
        '1000693',
        '1002',
        52.305732,
        20.9992471,
        '04BAE8F2304C80',
        '152',
        '',
        '',
        '',
        1003,
      ],
      [
        '1000712',
        '1000',
        52.3055939,
        20.9995329,
        '04C6F6F2304C80',
        '03',
        '',
        '',
        '',
        1003,
      ],
      [
        '1000713',
        '1000',
        52.3056881,
        20.9992755,
        '04F1EDDAA64A80',
        '122',
        '',
        '',
        '',
        1003,
      ],
      [
        '1000715',
        '1000',
        52.3056935,
        20.9992408,
        '04E2E4F2304C80',
        '153',
        '',
        '',
        '',
        1003,
      ],
      [
        '1000717',
        '1002',
        52.3057334,
        20.9992637,
        '0404F2F2304C81',
        'NN',
        '',
        '',
        '',
        1003,
      ],
      [
        '1000722',
        '1000',
        52.3055388,
        20.9995935,
        '04940DDAA64A84',
        '196',
        '',
        '',
        '',
        1003,
      ],
      [
        '1000731',
        '1000',
        52.3056826,
        20.9993482,
        '04F2EEF2304C80',
        '201',
        '',
        '',
        '',
        1002,
      ],
      [
        '1001243',
        '1000',
        52.305569,
        20.9993956,
        '04A907DAA64A84',
        '127',
        '',
        '',
        '',
        988,
      ],
      [
        '1001249',
        '1000',
        52.305683,
        20.9991096,
        '042CE8F2304C81',
        '178',
        '',
        '',
        '',
        988,
      ],
      [
        '1001250',
        '1000',
        52.3056704,
        20.9991603,
        '0480EEF2304C80',
        '128',
        '',
        '',
        '',
        988,
      ],
      [
        '1001251',
        '1002',
        52.3056755,
        20.9991804,
        '04BC0FDAA64A84',
        '126',
        '',
        '',
        '',
        988,
      ],
      [
        '1001252',
        '1000',
        52.3056367,
        20.9992193,
        '04E7EEF2304C80',
        '163',
        '',
        '',
        '',
        988,
      ],
      [
        '1001253',
        '1000',
        52.3056367,
        20.999345,
        '04D80EDAA64A84',
        '220',
        '',
        '',
        '',
        988,
      ],
      [
        '1001254',
        '1000',
        52.3055892,
        20.9991617,
        '04B907DAA64A84',
        '182',
        '',
        '',
        '',
        988,
      ],
      [
        '1001255',
        '1000',
        52.30578,
        20.9992067,
        '0406F2F2304C81',
        '180',
        '',
        '',
        '',
        988,
      ],
      [
        '1001256',
        '1000',
        52.3057056,
        20.9991892,
        '04C7E8F2304C80',
        '162',
        '',
        '',
        '',
        988,
      ],
      [
        '1001257',
        '1000',
        52.3056751,
        20.9991941,
        '04F7F2F2304C80',
        '165',
        '',
        '',
        '',
        988,
      ],
      [
        '1001258',
        '1000',
        52.3057039,
        20.9990998,
        '047907DAA64A84',
        '138',
        '',
        '',
        '',
        988,
      ],
      [
        '1001259',
        '1000',
        52.3057496,
        20.9990923,
        '0484ECDAA64A80',
        '118',
        '',
        '',
        '',
        988,
      ],
      [
        '1001260',
        '1000',
        52.3056907,
        20.9989092,
        '04AB08DAA64A84',
        '132',
        '',
        '',
        '',
        988,
      ],
      [
        '1001261',
        '1000',
        52.30549,
        20.9989133,
        '0459E4F2304C81',
        'Brak',
        '',
        '',
        '',
        988,
      ],
      [
        '1001262',
        '1002',
        52.3053835,
        20.9995548,
        '044FE4F2304C81',
        '114',
        '',
        '',
        '',
        988,
      ],
      [
        '1001263',
        '1000',
        52.3055628,
        20.9993325,
        '04C1EEF2304C80',
        '18',
        '',
        '',
        '',
        988,
      ],
    ],
  },
});
