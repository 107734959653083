export const fixture = () => ({
  __meta: { region: 'eu-west-1' },
  data: {
    statuses: [
      { id: '1004', name: 'Uszkodzony' },
      { id: '1008', name: 'Podstawienie.' },
      { id: '1009', name: 'Odbior' },
      { id: '1010', name: 'Serwis' },
      { id: '1000', name: 'Na bazie' },
      { id: '1006', name: 'Pobranie z Bazy' },
      { id: '1012', name: 'bardzo uszkodzony' },
    ],
    lines: ['linia1', 'linia2'],
  },
});
