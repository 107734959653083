import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from './store';
import { Project } from './types';

const projectsAdapter = createEntityAdapter<Project>({
  selectId: ({ ID }) => ID,
  sortComparer: (a, b) => a.NAZWA.localeCompare(b.NAZWA),
});

export const projectsSlice = createSlice({
  name: 'user',
  initialState: projectsAdapter.getInitialState(),
  reducers: {
    setProjects: (
      state,
      { payload: { projects } }: PayloadAction<{ projects: Project[] }>,
    ) => projectsAdapter.setAll(state, projects),
  },
});

export const { setProjects } = projectsSlice.actions;

const projectSelectors = projectsAdapter.getSelectors<RootState>(
  (state) => state.projects,
);

export const { selectAll: selectAllProjects } = projectSelectors;
