export const fixture = () => ({
  __meta: { region: 'us-east-1' },
  data: {
    pointsCount: 521,
    statuses: [
      { id: '1000', color: 3, position: 1, name: 'Na bazie' },
      { id: '1002', color: 1, position: 2, name: 'Podstawienie' },
      { id: '1006', color: 12, position: 3, name: 'Pobranie z Bazy' },
      { id: '1009', color: 4, position: 4, name: 'Odbior' },
      { id: '1004', color: 0, position: 5, name: 'Uszkodzony' },
      { id: '1010', color: 5, position: 6, name: 'Serwis' },
    ],
    attributes: [
      {
        name: 'id',
        editable: false,
        id: '-1',
        display: false,
        displayName: 'Identyfikator',
        type: 'T',
        visibleInWindow: false,
      },
      {
        name: 'statusId',
        editable: true,
        id: '-1',
        display: false,
        displayName: 'Status',
        type: 'S',
        visibleInWindow: false,
      },
      {
        name: 'latitude',
        editable: false,
        id: '-1',
        display: false,
        displayName: 'Szerokość geogr.',
        type: 'T',
        visibleInWindow: false,
      },
      {
        name: 'longitude',
        editable: false,
        id: '-1',
        display: true,
        displayName: 'Długość geogr.',
        type: 'T',
        visibleInWindow: false,
      },
      {
        name: 'nfcTagUid',
        editable: false,
        id: '-1',
        display: true,
        displayName: 'Numer NFC',
        type: 'T',
        visibleInWindow: false,
      },
      {
        name: 'title',
        editable: false,
        id: '-1',
        display: true,
        displayName: 'Numer ew.',
        type: 'T',
        visibleInWindow: true,
      },
      {
        id: '10000',
        name: 'Rodzaj',
        editable: true,
        type: 'OWO',
        visibleInWindow: false,
        displayName: 'Rodzaj',
        display: true,
      },
      {
        id: '10001',
        name: 'Uwagi',
        editable: true,
        type: 'MT',
        visibleInWindow: false,
        displayName: 'Uwagi',
        display: true,
      },
    ],
    options: [
      {
        id: 100003,
        value: 'Kabina Polska Standard',
        attributeId: 10000,
        index: 1,
      },
      {
        id: 100004,
        value: 'Kabina Polska Umywalka',
        attributeId: 10000,
        index: 2,
      },
      {
        id: 100005,
        value: 'Kabina Włoska Standard',
        attributeId: 10000,
        index: 3,
      },
      {
        id: 100006,
        value: 'Kabina Włoska Umywalka',
        attributeId: 10000,
        index: 4,
      },
      {
        id: 100007,
        value: 'Kabina Niemiecka Standard',
        attributeId: 10000,
        index: 5,
      },
      {
        id: 100008,
        value: 'Kabina Niemiecka Uwywalka',
        attributeId: 10000,
        index: 6,
      },
      {
        id: 100009,
        value: 'Kabina Niemiecka Niepełnosprawna',
        attributeId: 10000,
        index: 7,
      },
      { id: 100031, value: 'Kabina z Hakiem', attributeId: 10000, index: 8 },
    ],
    points: [
      [
        '1000224',
        '1002',
        52.1094115,
        20.7949766,
        'NFC1000224',
        '421',
        '',
        'uszkodzony zbiornik, pisuar po wymianie nabic numer 421',
        49,
      ],
      [
        '1000225',
        '1002',
        52.2177933,
        21.1474075,
        'NFC1000225',
        'B1',
        'Kabina Polska Umywalka',
        '',
        41,
      ],
      [
        '1000226',
        '1002',
        52.2996425,
        20.9903224,
        'NFC1000226',
        '587',
        'Kabina Niemiecka Standard',
        'zielona lub niebie',
        161,
      ],
      [
        '1000227',
        '1002',
        52.336706,
        21.200587,
        'NFC1000227',
        '157/473',
        'Kabina Polska Standard',
        'nabite dwa numery 3razy',
        453,
      ],
      [
        '1000228',
        '1002',
        52.3107594,
        20.9097685,
        'NFC1000228',
        '140/373',
        'Kabina Niemiecka Standard',
        'nabite dwa numery',
        425,
      ],
      [
        '1000229',
        '1002',
        52.4649275,
        21.0100772,
        'NFC1000229',
        '213',
        'Kabina Polska Umywalka',
        '',
        231,
      ],
      [
        '1000230',
        '1002',
        52.1455672,
        21.1931358,
        'NFC1000230',
        'B3',
        'Kabina Niemiecka Standard',
        'parcelowa 4',
        212,
      ],
      [
        '1000231',
        '1002',
        52.22485,
        21.1000668,
        'NFC1000231',
        '014',
        'Kabina Polska Standard',
        '',
        241,
      ],
      [
        '1000232',
        '1002',
        52.3542898,
        21.1185239,
        'NFC1000232',
        '156',
        'Kabina Polska Standard',
        '',
        675,
      ],
      [
        '1000233',
        '1002',
        52.2099896,
        21.0900201,
        'NFC1000233',
        '033/110',
        'Kabina Polska Standard',
        'nabite dwa numery',
        549,
      ],
      [
        '1000234',
        '1002',
        52.4093904,
        21.3262302,
        'NFC1000234',
        'B2',
        'Kabina Polska Standard',
        'brzozowa 10 wesola ',
        327,
      ],
      [
        '1000235',
        '1002',
        52.0890879,
        20.7959039,
        'NFC1000235',
        '113',
        'Kabina Polska Standard',
        'wyblakła do sprzedania',
        337,
      ],
      [
        '1000236',
        '1002',
        52.3419216,
        21.1268325,
        'NFC1000236',
        '422',
        'Kabina Niemiecka Standard',
        '',
        413,
      ],
      [
        '1000237',
        '1002',
        52.2096336,
        21.0453551,
        'NFC1000237',
        'B4',
        'Kabina Niemiecka Standard',
        'zielona',
        499,
      ],
      [
        '1000238',
        '1002',
        52.423797,
        20.8728197,
        'NFC1000238',
        '159',
        'Kabina Niemiecka Standard',
        '',
        586,
      ],
      [
        '1000255',
        '1002',
        52.3928615,
        20.8928465,
        'NFC1000255',
        'B5',
        'Kabina Niemiecka Standard',
        'zielona, przypalony zbiornik od gory Nałkowskiej 1 ',
        487,
      ],
      [
        '1000256',
        '1002',
        52.0971508,
        21.0027885,
        'NFC1000256',
        'B6',
        'Kabina Niemiecka Standard',
        'zielona',
        649,
      ],
      [
        '1000257',
        '1002',
        52.4151389,
        20.8906887,
        'NFC1000257',
        '130',
        'Kabina Niemiecka Standard',
        'niebieska, hak',
        704,
      ],
      [
        '1000258',
        '1002',
        52.2136511,
        21.1137362,
        'NFC1000258',
        '154',
        'Kabina Polska Standard',
        '',
        542,
      ],
      [
        '1000259',
        '1002',
        52.0534073,
        20.8836281,
        'NFC1000259',
        '442',
        'Kabina Niemiecka Standard',
        'niebieska',
        537,
      ],
      [
        '1000260',
        '1002',
        52.4715801,
        21.0678322,
        'NFC1000260',
        '88',
        'Kabina Polska Standard',
        '',
        443,
      ],
      [
        '1000261',
        '1002',
        52.188228,
        21.5829084,
        'NFC1000261',
        '196',
        'Kabina Polska Standard',
        '',
        398,
      ],
      [
        '1000263',
        '1002',
        52.0926564,
        21.0642681,
        'NFC1000263',
        'B7',
        'Kabina Niemiecka Standard',
        'zielona',
        546,
      ],
      [
        '1000267',
        '1002',
        52.3556049,
        21.0162157,
        'NFC1000267',
        'B8',
        'Kabina Niemiecka Standard',
        'niebieska',
        107,
      ],
      [
        '1000271',
        '1002',
        52.1792762,
        21.0652399,
        'NFC1000271',
        'B9',
        'Kabina Niemiecka Standard',
        'niebieska',
        183,
      ],
      [
        '1000272',
        '1002',
        52.3920831,
        21.053154,
        'NFC1000272',
        '066',
        'Kabina Niemiecka Standard',
        'niebieska',
        529,
      ],
      [
        '1000273',
        '1002',
        52.18764,
        20.8137791,
        'NFC1000273',
        '216/262',
        'Kabina Polska Standard',
        'nabite dwa numery',
        176,
      ],
      [
        '1000274',
        '1018',
        52.4843209,
        21.0495466,
        'NFC1000274',
        'B10',
        'Kabina Polska Standard',
        '',
        502,
      ],
      [
        '1000275',
        '1002',
        52.1410293,
        21.4222242,
        'NFC1000275',
        '306/372',
        'Kabina Polska Umywalka',
        'nawet z pisuarem',
        422,
      ],
      [
        '1000276',
        '1002',
        52.1695981,
        21.5917009,
        'NFC1000276',
        '269',
        'Kabina Polska Standard',
        '',
        371,
      ],
      [
        '1000277',
        '1002',
        52.2226731,
        20.993421,
        'NFC1000277',
        '419/052',
        'Kabina Niemiecka Standard',
        'niebieska, nabite dwa numery',
        493,
      ],
      [
        '1000279',
        '1002',
        52.1777637,
        21.2947905,
        'NFC1000279',
        '032',
        'Kabina Polska Standard',
        'boryszewska 1 wiazowna ',
        141,
      ],
      [
        '1000280',
        '1002',
        52.1347679,
        20.7807687,
        'NFC1000280',
        '153',
        'Kabina Niemiecka Standard',
        '',
        404,
      ],
      [
        '1000281',
        '1002',
        52.4569352,
        21.3865713,
        'NFC1000281',
        '000',
        'Kabina Polska Standard',
        'brak numeru i tabliczki na drzwiach ',
        367,
      ],
      [
        '1000282',
        '1002',
        52.3913336,
        21.0064168,
        'NFC1000282',
        '322',
        'Kabina Polska Umywalka',
        '',
        202,
      ],
      [
        '1000284',
        '1002',
        52.0339637,
        21.1097578,
        'NFC1000284',
        '264',
        'Kabina Polska Standard',
        '',
        577,
      ],
      [
        '1000285',
        '1002',
        52.1751404,
        20.5782624,
        'NFC1000285',
        '071',
        'Kabina Polska Standard',
        '',
        605,
      ],
      [
        '1000286',
        '1002',
        52.3441542,
        20.9475129,
        'NFC1000286',
        '500',
        'Kabina Polska Umywalka',
        'bez bańki na wodę ',
        396,
      ],
      [
        '1000287',
        '1002',
        52.3636329,
        21.1171676,
        'NFC1000287',
        'Z1',
        'Kabina Niemiecka Standard',
        '',
        200,
      ],
      [
        '1000289',
        '1002',
        52.1670614,
        21.1778472,
        'NFC1000289',
        'B11',
        'Kabina Polska Standard',
        '',
        315,
      ],
      [
        '1000290',
        '1002',
        52.1614837,
        20.9088534,
        'NFC1000290',
        'B12',
        'Kabina Włoska Umywalka',
        'zielona',
        520,
      ],
      [
        '1000291',
        '1000',
        52.3729716,
        21.0302069,
        'NFC1000291',
        '206',
        'Kabina Polska Umywalka',
        '',
        351,
      ],
      [
        '1000292',
        '1002',
        52.5012221,
        21.0237173,
        'NFC1000292',
        '228/562',
        'Kabina Polska Umywalka',
        'nabite dwa numery',
        103,
      ],
      [
        '1000293',
        '1002',
        52.3594814,
        21.0642547,
        'NFC1000293',
        '73',
        'Kabina Polska Umywalka',
        '',
        553,
      ],
      [
        '1000294',
        '1002',
        52.2542215,
        21.1285559,
        'NFC1000294',
        'B13',
        'Kabina Niemiecka Uwywalka',
        'zielona',
        266,
      ],
      [
        '1000295',
        '1002',
        52.124655,
        20.6394377,
        'NFC1000295',
        'B066',
        'Kabina Polska Standard',
        '',
        422,
      ],
      [
        '1000296',
        '1002',
        52.2297642,
        21.1087896,
        'NFC1000296',
        'B14',
        'Kabina Niemiecka Uwywalka',
        'zielona',
        671,
      ],
      [
        '1000297',
        '1002',
        52.0969395,
        20.7440183,
        'NFC1000297',
        'B15',
        'Kabina Niemiecka Uwywalka',
        'zielona, porysowany bok i mała dziura',
        671,
      ],
      [
        '1000298',
        '1002',
        52.2515737,
        20.856983,
        'NFC1000298',
        '104',
        'Kabina Polska Umywalka',
        '',
        434,
      ],
      [
        '1000299',
        '1002',
        52.0972766,
        21.050282,
        'NFC1000299',
        'B16',
        'Kabina Niemiecka Uwywalka',
        'zielona z lusterkiem',
        275,
      ],
      [
        '1000300',
        '1002',
        52.4400419,
        21.2859845,
        'NFC1000300',
        '78',
        'Kabina Niemiecka Uwywalka',
        'zielona porysowane drzwi z zewnątrz ',
        80,
      ],
      [
        '1000301',
        '1002',
        52.1624582,
        20.8917924,
        'NFC1000301',
        'B17',
        'Kabina Niemiecka Uwywalka',
        'zielona',
        584,
      ],
      [
        '1000302',
        '1002',
        52.4902897,
        20.9671587,
        'NFC1000302',
        'B18',
        'Kabina Niemiecka Uwywalka',
        'zielona',
        664,
      ],
      [
        '1000303',
        '1002',
        52.640499,
        20.9507164,
        'NFC1000303',
        '079 (DUPLIKAT)',
        'Kabina Niemiecka Uwywalka',
        'zielona',
        355,
      ],
      [
        '1000304',
        '1002',
        52.076874,
        21.1173845,
        'NFC1000304',
        '374',
        'Kabina Niemiecka Uwywalka',
        'zielona',
        174,
      ],
      [
        '1000305',
        '1002',
        52.2116534,
        20.9921136,
        'NFC1000305',
        'B19',
        'Kabina Niemiecka Uwywalka',
        'zielona z lusterkiem',
        374,
      ],
      [
        '1000306',
        '1002',
        52.3507185,
        21.1181354,
        'NFC1000306',
        '075',
        'Kabina Niemiecka Uwywalka',
        '',
        529,
      ],
      [
        '1000307',
        '1002',
        52.2819888,
        20.9351259,
        'NFC1000307',
        '170/583',
        'Kabina Niemiecka Uwywalka',
        'zielona nabite dwa numery',
        298,
      ],
      [
        '1000308',
        '1002',
        52.2805262,
        21.0145676,
        'NFC1000308',
        'B20',
        'Kabina Niemiecka Uwywalka',
        'zielona',
        472,
      ],
      [
        '1000309',
        '1000',
        52.373321,
        21.0312598,
        'NFC1000309',
        '074/377',
        'Kabina Niemiecka Uwywalka',
        'zielona nabite dwa numery',
        82,
      ],
      [
        '1000310',
        '1002',
        52.2829732,
        21.1165321,
        'NFC1000310',
        'B21',
        'Kabina Niemiecka Uwywalka',
        'zielona',
        337,
      ],
      [
        '1000311',
        '1002',
        51.9958849,
        20.9198323,
        'NFC1000311',
        'B22',
        'Kabina Niemiecka Uwywalka',
        'zielona',
        409,
      ],
      [
        '1000312',
        '1002',
        52.1568006,
        21.0898077,
        'NFC1000312',
        '070/364',
        'Kabina Polska Standard',
        'nabite dwa numer',
        319,
      ],
      [
        '1000313',
        '1002',
        52.1897674,
        21.1538763,
        'NFC1000313',
        '147',
        'Kabina Polska Standard',
        '',
        434,
      ],
      [
        '1000315',
        '1002',
        52.09459,
        20.6733381,
        'NFC1000315',
        'p1',
        'Kabina Niemiecka Standard',
        'zielona',
        489,
      ],
      [
        '1000316',
        '1002',
        52.3795222,
        21.3650742,
        'NFC1000316',
        'p2',
        'Kabina Niemiecka Uwywalka',
        'niebieska',
        432,
      ],
      [
        '1000317',
        '1002',
        52.3651551,
        21.1214369,
        'NFC1000317',
        '056/134',
        'Kabina Niemiecka Standard',
        'niebieska ',
        364,
      ],
      [
        '1000318',
        '1002',
        52.4364287,
        20.8585611,
        'NFC1000318',
        '192/127',
        'Kabina Polska Standard',
        '',
        588,
      ],
      [
        '1000319',
        '1002',
        52.3478019,
        21.0641499,
        'NFC1000319',
        'p3',
        'Kabina Włoska Standard',
        'brak',
        525,
      ],
      [
        '1000320',
        '1002',
        52.3047161,
        20.9998266,
        'NFC1000320',
        'z3',
        'Kabina Niemiecka Standard',
        'niebieska',
        658,
      ],
      [
        '1000321',
        '1002',
        52.1838191,
        21.0890153,
        'NFC1000321',
        '394',
        'Kabina Niemiecka Standard',
        '',
        530,
      ],
      [
        '1000322',
        '1002',
        52.1838518,
        21.0887645,
        'NFC1000322',
        '393',
        'Kabina Niemiecka Uwywalka',
        '',
        123,
      ],
      [
        '1000323',
        '1002',
        52.2357989,
        20.8860483,
        'NFC1000323',
        '052',
        'Kabina Polska Standard',
        '',
        100,
      ],
      [
        '1000324',
        '1002',
        52.0795112,
        20.8630515,
        'NFC1000324',
        '195460',
        'Kabina Polska Standard',
        '',
        339,
      ],
      [
        '1000325',
        '1002',
        52.4089154,
        21.1390749,
        'NFC1000325',
        'p4',
        'Kabina Polska Standard',
        '',
        545,
      ],
      [
        '1000326',
        '1002',
        52.092822,
        20.7254122,
        'NFC1000326',
        '012',
        'Kabina Niemiecka Standard',
        '',
        203,
      ],
      [
        '1000327',
        '1002',
        52.2240581,
        21.154837,
        'NFC1000327',
        'z4',
        'Kabina Polska Standard',
        '',
        452,
      ],
      [
        '1000328',
        '1002',
        52.3188449,
        21.0250515,
        'NFC1000328',
        '208566',
        'Kabina Polska Umywalka',
        '',
        261,
      ],
      [
        '1000329',
        '1002',
        52.3205553,
        21.1991758,
        'NFC1000329',
        '126',
        'Kabina Niemiecka Standard',
        'niebieska ',
        351,
      ],
      [
        '1000330',
        '1002',
        52.0599518,
        20.6432893,
        'NFC1000330',
        'w1',
        'Kabina Włoska Standard',
        '',
        683,
      ],
      [
        '1000331',
        '1002',
        52.0919896,
        21.1125762,
        'NFC1000331',
        '146/461',
        'Kabina Polska Standard',
        '',
        526,
      ],
      [
        '1000332',
        '1002',
        52.1473005,
        20.7193738,
        'NFC1000332',
        '248',
        'Kabina Polska Standard',
        '',
        366,
      ],
      [
        '1000333',
        '1002',
        52.2462565,
        21.0188857,
        'NFC1000333',
        'p6',
        'Kabina Niemiecka Standard',
        'niebieska',
        428,
      ],
      [
        '1000334',
        '1002',
        52.2812256,
        21.1289184,
        'NFC1000334',
        'p5',
        'Kabina Niemiecka Standard',
        'niebieska',
        156,
      ],
      [
        '1000335',
        '1002',
        52.4144261,
        21.2121704,
        'NFC1000335',
        'z5',
        'Kabina Niemiecka Standard',
        'zielona ',
        447,
      ],
      [
        '1000336',
        '1002',
        52.1842069,
        20.5693372,
        'NFC1000336',
        '354',
        'Kabina Polska Umywalka',
        'brak baniaka',
        661,
      ],
      [
        '1000337',
        '1002',
        52.0206949,
        21.0489304,
        'NFC1000337',
        '435',
        'Kabina Niemiecka Standard',
        'niebieska ',
        376,
      ],
      [
        '1000338',
        '1002',
        52.0884862,
        21.001737,
        'NFC1000338',
        '560 (DUPLIKAT)',
        'Kabina Włoska Standard',
        '',
        529,
      ],
      [
        '1000339',
        '1002',
        52.0959361,
        20.6305326,
        'NFC1000339',
        'Z6',
        'Kabina Niemiecka Standard',
        'Niebieska ',
        500,
      ],
      [
        '1000341',
        '1002',
        52.3869175,
        21.2890078,
        'NFC1000341',
        'p7',
        'Kabina Niemiecka Standard',
        'niebiesks',
        616,
      ],
      [
        '1000342',
        '1002',
        52.4013958,
        20.7361568,
        'NFC1000342',
        '194',
        'Kabina Polska Standard',
        '',
        490,
      ],
      [
        '1000343',
        '1002',
        52.099191,
        21.3525954,
        'NFC1000343',
        'p8',
        'Kabina Niemiecka Standard',
        'niebieska',
        451,
      ],
      [
        '1000344',
        '1002',
        52.4748483,
        21.0142115,
        'NFC1000344',
        'w2',
        '',
        'rod ',
        337,
      ],
      [
        '1000345',
        '1002',
        52.1175197,
        21.023633,
        'NFC1000345',
        'Z7',
        'Kabina Niemiecka Standard',
        'Niebieska ',
        183,
      ],
      [
        '1000346',
        '1002',
        52.4439215,
        20.615019,
        'NFC1000346',
        '443',
        'Kabina Polska Standard',
        '',
        350,
      ],
      [
        '1000347',
        '1002',
        51.7580006,
        20.7053483,
        'NFC1000347',
        '009',
        'Kabina Polska Standard',
        '',
        172,
      ],
      [
        '1000348',
        '1002',
        52.0139523,
        20.9649037,
        'NFC1000348',
        '450',
        'Kabina Niemiecka Standard',
        'niebieska ',
        59,
      ],
      [
        '1000349',
        '1002',
        52.2076648,
        20.7143918,
        'NFC1000349',
        '0036',
        'Kabina Włoska Standard',
        '',
        454,
      ],
      [
        '1000351',
        '1000',
        52.3729781,
        21.030056,
        'NFC1000351',
        '158q',
        'Kabina Polska Standard',
        '',
        366,
      ],
      [
        '1000352',
        '1002',
        52.4576388,
        20.7178186,
        'NFC1000352',
        '095',
        'Kabina Polska Standard',
        '',
        301,
      ],
      [
        '1000353',
        '1002',
        52.3431735,
        20.9511138,
        'NFC1000353',
        '023 (DUPLIKAT)',
        'Kabina Niemiecka Uwywalka',
        'zielona ',
        55,
      ],
      [
        '1000354',
        '1002',
        52.097123,
        21.2744984,
        'NFC1000354',
        'z8',
        'Kabina Niemiecka Standard',
        'niebieska ',
        423,
      ],
      [
        '1000355',
        '1002',
        52.4026246,
        20.8891371,
        'NFC1000355',
        'p9',
        'Kabina Niemiecka Standard',
        '',
        310,
      ],
      [
        '1000356',
        '1002',
        52.1189756,
        20.9740726,
        'NFC1000356',
        '56',
        '',
        '',
        273,
      ],
      [
        '1000357',
        '1002',
        52.4223359,
        20.7284714,
        'NFC1000357',
        '070',
        '',
        '',
        96,
      ],
      [
        '1000358',
        '1002',
        52.1481728,
        21.0442696,
        'NFC1000358',
        'p10',
        'Kabina Niemiecka Uwywalka',
        '',
        317,
      ],
      [
        '1000359',
        '1002',
        52.1091344,
        21.2798203,
        'NFC1000359',
        'p11',
        'Kabina Włoska Standard',
        '',
        36,
      ],
      [
        '1000360',
        '1002',
        52.5365492,
        21.0208759,
        'NFC1000360',
        'p11\\n',
        'Kabina Niemiecka Standard',
        '',
        372,
      ],
      [
        '1000361',
        '1002',
        52.25499,
        21.1669825,
        'NFC1000361',
        'p12',
        'Kabina Niemiecka Standard',
        'zielona',
        585,
      ],
      [
        '1000362',
        '1002',
        52.4977424,
        21.1497742,
        'NFC1000362',
        'p13',
        'Kabina Niemiecka Standard',
        'niebieska',
        151,
      ],
      [
        '1000363',
        '1002',
        52.0752721,
        21.2473284,
        'NFC1000363',
        'R1',
        'Kabina Polska Umywalka',
        'bez baniaka na wode',
        44,
      ],
      [
        '1000364',
        '1002',
        52.0005424,
        21.0930862,
        'NFC1000364',
        '120/445',
        'Kabina Niemiecka Standard',
        'niebieska',
        520,
      ],
      [
        '1000365',
        '1002',
        52.2423172,
        20.9410246,
        'NFC1000365',
        'p14',
        'Kabina Włoska Standard',
        '',
        580,
      ],
      [
        '1000366',
        '1002',
        52.13026,
        21.2172683,
        'NFC1000366',
        '589',
        'Kabina Niemiecka Uwywalka',
        '',
        692,
      ],
      [
        '1000367',
        '1002',
        52.148406,
        20.9009655,
        'NFC1000367',
        '166',
        'Kabina Polska Standard',
        '',
        150,
      ],
      [
        '1000368',
        '1002',
        52.2027339,
        20.720283,
        'NFC1000368',
        '178/472',
        'Kabina Polska Standard',
        '.',
        546,
      ],
      [
        '1000369',
        '1002',
        52.2350343,
        20.9262141,
        'NFC1000369',
        'p15',
        'Kabina Polska Standard',
        '',
        307,
      ],
      [
        '1000370',
        '1002',
        52.3225296,
        21.0704332,
        'NFC1000370',
        '129 (DUPLIKAT)',
        'Kabina Polska Standard',
        'wyblakła',
        542,
      ],
      [
        '1000371',
        '1002',
        52.4873418,
        21.0341393,
        'NFC1000371',
        '093',
        'Kabina Niemiecka Standard',
        'niebieska',
        47,
      ],
      [
        '1000372',
        '1002',
        52.1270655,
        20.9630793,
        'NFC1000372',
        '220 (DUPLIKAT)',
        'Kabina Polska Standard',
        'przeniesiona ',
        179,
      ],
      [
        '1000373',
        '1002',
        52.1992509,
        21.5061686,
        'NFC1000373',
        'R2',
        'Kabina Niemiecka Standard',
        'niebieska',
        583,
      ],
      [
        '1000374',
        '1002',
        52.1535678,
        21.1769587,
        'NFC1000374',
        'p16',
        'Kabina Niemiecka Standard',
        'zielona',
        311,
      ],
      [
        '1000376',
        '1002',
        52.3231145,
        21.0690549,
        'NFC1000376',
        '400',
        'Kabina Niemiecka Standard',
        'niebieska',
        244,
      ],
      [
        '1000377',
        '1002',
        52.3299053,
        21.1112068,
        'NFC1000377',
        'R3',
        'Kabina Niemiecka Standard',
        'niebieska',
        693,
      ],
      [
        '1000378',
        '1002',
        52.2007822,
        20.8593003,
        'NFC1000378',
        'p17',
        'Kabina Niemiecka Standard',
        'niebieska',
        149,
      ],
      [
        '1000379',
        '1000',
        52.3731156,
        21.0305499,
        'NFC1000379',
        'p18',
        'Kabina Włoska Umywalka',
        '',
        481,
      ],
      [
        '1000381',
        '1002',
        52.318535,
        21.1281245,
        'NFC1000381',
        'R4',
        'Kabina Niemiecka Standard',
        'niebieska',
        205,
      ],
      [
        '1000382',
        '1002',
        52.3719376,
        21.0276166,
        'NFC1000382',
        '0008',
        'Kabina Włoska Standard',
        '',
        212,
      ],
      [
        '1000383',
        '1002',
        52.4043614,
        20.6510645,
        'NFC1000383',
        '072',
        'Kabina Niemiecka Standard',
        'niebieska',
        173,
      ],
      [
        '1000384',
        '1002',
        52.1090321,
        20.9833562,
        'NFC1000384',
        '209',
        'Kabina Polska Standard',
        'uszkodzona deska',
        541,
      ],
      [
        '1000385',
        '1002',
        52.5065511,
        21.0689697,
        'NFC1000385',
        'R5',
        'Kabina Niemiecka Standard',
        'niebieska',
        60,
      ],
      [
        '1000386',
        '1002',
        52.3854983,
        20.9175576,
        'NFC1000386',
        '218',
        'Kabina Polska Standard',
        '',
        576,
      ],
      [
        '1000387',
        '1002',
        52.3775862,
        20.9437784,
        'NFC1000387',
        'p21',
        'Kabina Niemiecka Standard',
        'niebieska',
        68,
      ],
      [
        '1000388',
        '1002',
        52.4491776,
        20.6915844,
        'NFC1000388',
        'p20',
        'Kabina Niemiecka Uwywalka',
        'zielona',
        314,
      ],
      [
        '1000389',
        '1002',
        52.5157618,
        21.0758347,
        'NFC1000389',
        'p19',
        'Kabina Włoska Standard',
        '',
        470,
      ],
      [
        '1000390',
        '1002',
        52.5746895,
        21.4541621,
        'NFC1000390',
        '467',
        'Kabina Polska Standard',
        '',
        431,
      ],
      [
        '1000391',
        '1000',
        52.3731357,
        21.0292537,
        'NFC1000391',
        'R6',
        'Kabina Niemiecka Standard',
        'niebieska',
        387,
      ],
      [
        '1000392',
        '1002',
        52.0743484,
        21.2454647,
        'NFC1000392',
        'p22',
        'Kabina Polska Standard',
        'kabina uszkodzona wymiana deski i przepalona',
        181,
      ],
      [
        '1000394',
        '1002',
        52.3849203,
        20.9218843,
        'NFC1000394',
        '245',
        'Kabina Polska Standard',
        '',
        328,
      ],
      [
        '1000395',
        '1002',
        52.1089126,
        21.1055576,
        'NFC1000395',
        'p24',
        'Kabina Niemiecka Standard',
        'niebieska',
        636,
      ],
      [
        '1000396',
        '1002',
        52.453622,
        21.2999428,
        'NFC1000396',
        '007 (DUPLIKAT)',
        'Kabina Polska Standard',
        '',
        485,
      ],
      [
        '1000397',
        '1002',
        52.404105,
        20.8131313,
        'NFC1000397',
        'R7',
        'Kabina Niemiecka Standard',
        'niebieska, uszkodzona sciana',
        714,
      ],
      [
        '1000398',
        '1002',
        52.1920305,
        20.6037159,
        'NFC1000398',
        '243',
        'Kabina Polska Standard',
        '',
        205,
      ],
      [
        '1000399',
        '1000',
        52.3730732,
        21.0303485,
        'NFC1000399',
        '238',
        'Kabina Polska Standard',
        '',
        435,
      ],
      [
        '1000400',
        '1002',
        52.4079059,
        21.1903429,
        'NFC1000400',
        '241',
        'Kabina Polska Standard',
        '',
        609,
      ],
      [
        '1000401',
        '1000',
        52.3729163,
        21.02946,
        'NFC1000401',
        '014a',
        'Kabina Polska Standard',
        '',
        124,
      ],
      [
        '1000402',
        '1002',
        52.373095,
        20.9411727,
        'NFC1000402',
        'R8',
        'Kabina Niemiecka Standard',
        'niebieska',
        631,
      ],
      [
        '1000403',
        '1000',
        52.3730695,
        21.0302957,
        'NFC1000403',
        'p26',
        'Kabina Niemiecka Standard',
        'niebieska z hakiem ',
        408,
      ],
      [
        '1000404',
        '1002',
        52.3257164,
        20.983006,
        'NFC1000404',
        'p27',
        'Kabina Włoska Standard',
        'Niemiecka standard Piotrek się pomylił prawidłowy nr 215 nabity na kabinie ( jest druga taka z tym samym numerem zaczipowana)',
        595,
      ],
      [
        '1000405',
        '1002',
        52.4865313,
        20.9980114,
        'NFC1000405',
        'p28',
        'Kabina Niemiecka Standard',
        'niebieska',
        121,
      ],
      [
        '1000406',
        '1002',
        52.4361474,
        20.9332209,
        'NFC1000406',
        '150',
        'Kabina Polska Standard',
        '',
        108,
      ],
      [
        '1000408',
        '1002',
        52.5000477,
        21.0590336,
        'NFC1000408',
        'p29',
        'Kabina Niemiecka Standard',
        'zielona',
        205,
      ],
      [
        '1000409',
        '1002',
        52.4048192,
        20.8838331,
        'NFC1000409',
        '097',
        'Kabina Polska Standard',
        '',
        335,
      ],
      [
        '1000410',
        '1009',
        51.9382311,
        20.7285534,
        'NFC1000410',
        'p301',
        'Kabina Polska Standard',
        '',
        544,
      ],
      [
        '1000411',
        '1002',
        51.7725159,
        20.7047417,
        'NFC1000411',
        'p31',
        'Kabina Polska Standard',
        '',
        186,
      ],
      [
        '1000412',
        '1002',
        52.2447552,
        21.1342648,
        'NFC1000412',
        'p32',
        'Kabina Niemiecka Standard',
        'niebieska',
        530,
      ],
      [
        '1000413',
        '1002',
        52.4120461,
        20.7468204,
        'NFC1000413',
        '304',
        'Kabina Polska Standard',
        '',
        480,
      ],
      [
        '1000414',
        '1002',
        52.3530698,
        20.8980658,
        'NFC1000414',
        '102',
        'Kabina Polska Standard',
        '',
        546,
      ],
      [
        '1000415',
        '1002',
        52.171459,
        21.1073156,
        'NFC1000415',
        '079',
        'Kabina Polska Umywalka',
        '',
        678,
      ],
      [
        '1000416',
        '1002',
        52.0936687,
        20.9567114,
        'NFC1000416',
        '222',
        'Kabina Polska Standard',
        '',
        138,
      ],
      [
        '1000417',
        '1002',
        52.3850353,
        21.189989,
        'NFC1000417',
        '250414',
        'Kabina Polska Standard',
        '',
        353,
      ],
      [
        '1000418',
        '1002',
        52.3382012,
        21.2676229,
        'NFC1000418',
        '188',
        'Kabina Włoska Standard',
        '',
        673,
      ],
      [
        '1000419',
        '1002',
        52.0573442,
        21.2873355,
        'NFC1000419',
        'z9 ',
        'Kabina Polska Umywalka',
        'brak oznakowania z prawej strony ',
        77,
      ],
      [
        '1000420',
        '1002',
        52.1243831,
        20.9550075,
        'NFC1000420',
        '029',
        'Kabina Polska Standard',
        '',
        263,
      ],
      [
        '1000421',
        '1002',
        52.1282935,
        20.9713908,
        'NFC1000421',
        '480',
        'Kabina Polska Standard',
        '',
        333,
      ],
      [
        '1000422',
        '1002',
        52.3521775,
        20.895446,
        'NFC1000422',
        '426',
        'Kabina Polska Standard',
        '',
        59,
      ],
      [
        '1000423',
        '1002',
        52.0978743,
        21.2638855,
        'NFC1000423',
        '101 (DUPLIKAT)',
        'Kabina Niemiecka Standard',
        'niebieska ',
        684,
      ],
      [
        '1000424',
        '1002',
        52.3276232,
        20.9718555,
        'NFC1000424',
        'z10 ',
        'Kabina Niemiecka Standard',
        'zielona obok niebieskiej ',
        356,
      ],
      [
        '1000425',
        '1002',
        52.3294084,
        21.0585468,
        'NFC1000425',
        '074',
        'Kabina Polska Standard',
        '',
        187,
      ],
      [
        '1000426',
        '1002',
        52.1364004,
        21.1950671,
        'NFC1000426',
        '062',
        'Kabina Polska Umywalka',
        'bez bańki na wodę',
        589,
      ],
      [
        '1000427',
        '1002',
        52.1525586,
        21.0637217,
        'NFC1000427',
        '073 (DUPLIKAT)',
        'Kabina Polska Standard',
        '',
        36,
      ],
      [
        '1000428',
        '1002',
        52.1198624,
        21.2468157,
        'NFC1000428',
        'Z11',
        'Kabina Niemiecka Standard',
        'niebieska ',
        603,
      ],
      [
        '1000429',
        '1002',
        52.1090824,
        21.0240381,
        'NFC1000429',
        '124 (DUPLIKAT)',
        'Kabina Polska Standard',
        'wyblakła uszkodzony zbiornik',
        253,
      ],
      [
        '1000430',
        '1002',
        52.492929,
        20.69598,
        'NFC1000430',
        '100 (DUPLIKAT)',
        'Kabina Polska Standard',
        '',
        650,
      ],
      [
        '1000431',
        '1002',
        52.1839649,
        20.8728752,
        'NFC1000431',
        '103',
        '',
        '',
        705,
      ],
      [
        '1000432',
        '1002',
        51.9764958,
        21.0979927,
        'NFC1000432',
        '257',
        '',
        '',
        553,
      ],
      [
        '1000433',
        '1002',
        52.2251797,
        21.1422411,
        'NFC1000433',
        '007/114',
        '',
        '',
        446,
      ],
      [
        '1000434',
        '1002',
        52.1394219,
        21.2395871,
        'NFC1000434',
        '577',
        '',
        '',
        555,
      ],
      [
        '1000435',
        '1002',
        52.3668459,
        21.2916777,
        'NFC1000435',
        '024',
        '',
        '',
        256,
      ],
      [
        '1000437',
        '1002',
        52.3210279,
        21.0484535,
        'NFC1000437',
        '043/409',
        '',
        '',
        647,
      ],
      [
        '1000438',
        '1002',
        52.3539059,
        21.0452029,
        'NFC1000438',
        '060 (DUPLIKAT)',
        '',
        '',
        439,
      ],
      [
        '1000439',
        '1002',
        52.1309653,
        20.6419481,
        'NFC1000439',
        '103/136',
        '',
        '',
        372,
      ],
      [
        '1000440',
        '1002',
        52.1091309,
        20.5846148,
        'NFC1000440',
        '027511',
        '',
        '',
        651,
      ],
      [
        '1000441',
        '1002',
        52.3130275,
        21.0314153,
        'NFC1000441',
        'Z12',
        '',
        '',
        405,
      ],
      [
        '1000442',
        '1002',
        52.1733654,
        20.8968317,
        'NFC1000442',
        'R400',
        '',
        '',
        367,
      ],
      [
        '1000443',
        '1002',
        52.190542,
        21.1349529,
        'NFC1000443',
        '167',
        '',
        '',
        355,
      ],
      [
        '1000444',
        '1002',
        52.3987254,
        21.1478709,
        'NFC1000444',
        '123',
        '',
        '',
        58,
      ],
      [
        '1000445',
        '1002',
        52.3043245,
        21.0474341,
        'NFC1000445',
        '019/502',
        '',
        '',
        193,
      ],
      [
        '1000446',
        '1002',
        52.1356646,
        21.1069081,
        'NFC1000446',
        '082260',
        '',
        '',
        210,
      ],
      [
        '1000447',
        '1002',
        52.3049307,
        20.9402699,
        'NFC1000447',
        'Z13',
        '',
        '',
        685,
      ],
      [
        '1000448',
        '1002',
        52.2408211,
        21.1907149,
        'NFC1000448',
        '314',
        '',
        '',
        184,
      ],
      [
        '1000449',
        '1002',
        52.2009553,
        21.4732169,
        'NFC1000449',
        'Z14',
        '',
        '',
        384,
      ],
      [
        '1000450',
        '1002',
        52.0605977,
        20.8645585,
        'NFC1000450',
        '207',
        '',
        '',
        609,
      ],
      [
        '1000451',
        '1000',
        52.3729879,
        21.0301714,
        'NFC1000451',
        '11',
        '',
        '',
        681,
      ],
      [
        '1000452',
        '1002',
        52.10432,
        20.8048934,
        'NFC1000452',
        '391',
        '',
        '',
        374,
      ],
      [
        '1000453',
        '1002',
        52.1656656,
        21.2400784,
        'NFC1000453',
        '44',
        '',
        '',
        305,
      ],
      [
        '1000454',
        '1002',
        52.5773443,
        20.7778282,
        'NFC1000454',
        '469',
        '',
        '',
        427,
      ],
      [
        '1000455',
        '1002',
        52.0993911,
        21.110838,
        'NFC1000455',
        '088462',
        '',
        '',
        529,
      ],
      [
        '1000456',
        '1002',
        52.0614776,
        20.8802983,
        'NFC1000456',
        'Z15',
        '',
        '',
        483,
      ],
      [
        '1000457',
        '1002',
        52.1049449,
        20.6723879,
        'NFC1000457',
        '036',
        '',
        '',
        466,
      ],
      [
        '1000458',
        '1002',
        52.0392133,
        20.6588626,
        'NFC1000458',
        '411',
        '',
        '',
        202,
      ],
      [
        '1000480',
        '1002',
        52.1519967,
        21.2389072,
        'NFC1000480',
        'Z16',
        '',
        '',
        668,
      ],
      [
        '1000481',
        '1002',
        52.1965952,
        21.2906614,
        'NFC1000481',
        '220',
        '',
        '',
        299,
      ],
      [
        '1000482',
        '1002',
        52.3003956,
        20.8216222,
        'NFC1000482',
        'Z126',
        '',
        '',
        444,
      ],
      [
        '1000483',
        '1002',
        52.4436715,
        21.1059585,
        'NFC1000483',
        'z17',
        '',
        '',
        689,
      ],
      [
        '1000484',
        '1002',
        52.3543015,
        21.0270494,
        'NFC1000484',
        '053',
        '',
        '',
        44,
      ],
      [
        '1000485',
        '1002',
        52.1630415,
        21.3407833,
        'NFC1000485',
        'z18',
        '',
        '',
        702,
      ],
      [
        '1000486',
        '1002',
        52.1142201,
        21.2320134,
        'NFC1000486',
        '135/132',
        '',
        '',
        624,
      ],
      [
        '1000488',
        '1002',
        52.2245303,
        21.2519789,
        'NFC1000488',
        'Z19',
        '',
        '',
        714,
      ],
      [
        '1000489',
        '1002',
        52.1812255,
        20.8340969,
        'NFC1000489',
        '185',
        '',
        '',
        27,
      ],
      [
        '1000490',
        '1002',
        52.126401,
        20.6534564,
        'NFC1000490',
        '560',
        '',
        '',
        539,
      ],
      [
        '1000491',
        '1002',
        52.0611202,
        20.8918185,
        'NFC1000491',
        '124',
        '',
        '',
        480,
      ],
      [
        '1000493',
        '1000',
        52.3730632,
        21.0301276,
        'NFC1000493',
        '186',
        '',
        '',
        78,
      ],
      [
        '1000494',
        '1002',
        52.2710732,
        21.309148,
        'NFC1000494',
        '191',
        '',
        'bez zbiornika na wodę ',
        264,
      ],
      [
        '1000495',
        '1002',
        52.1846427,
        20.5708205,
        'NFC1000495',
        '132',
        'Kabina Niemiecka Standard',
        'niebieska ',
        397,
      ],
      [
        '1000496',
        '1002',
        52.2727519,
        21.0482465,
        'NFC1000496',
        '408 (DUPLIKAT)',
        'Kabina Niemiecka Uwywalka',
        'zielona ',
        704,
      ],
      [
        '1000497',
        '1002',
        52.1896716,
        21.3475692,
        'NFC1000497',
        '119',
        'Kabina Niemiecka Uwywalka',
        'zielona ',
        530,
      ],
      [
        '1000498',
        '1002',
        52.2793173,
        21.1313484,
        'NFC1000498',
        'z20 ',
        'Kabina Niemiecka Standard',
        'zielona ',
        613,
      ],
      [
        '1000499',
        '1002',
        52.0910917,
        20.6476699,
        'NFC1000499',
        'Z21',
        'Kabina Polska Standard',
        '',
        104,
      ],
      [
        '1000500',
        '1002',
        52.1952005,
        20.6035145,
        'NFC1000500',
        '267',
        'Kabina Polska Umywalka',
        '',
        131,
      ],
      [
        '1000501',
        '1002',
        52.5257552,
        20.6817019,
        'NFC1000501',
        'Z22',
        'Kabina Niemiecka Standard',
        'Niebieska ',
        647,
      ],
      [
        '1000502',
        '1002',
        52.3075781,
        20.9164751,
        'NFC1000502',
        '051/572',
        'Kabina Polska Standard',
        '',
        362,
      ],
      [
        '1000503',
        '1002',
        52.1969822,
        20.6997093,
        'NFC1000503',
        '020/224',
        'Kabina Polska Standard',
        '',
        327,
      ],
      [
        '1000504',
        '1002',
        51.8436217,
        20.7519932,
        'NFC1000504',
        '573',
        'Kabina Niemiecka Standard',
        '',
        641,
      ],
      [
        '1000505',
        '1002',
        51.8221203,
        20.8152777,
        'NFC1000505',
        'Z23',
        'Kabina Niemiecka Standard',
        'zielona ',
        645,
      ],
      [
        '1000506',
        '1002',
        52.3331142,
        20.9497124,
        'NFC1000506',
        '105',
        'Kabina Niemiecka Standard',
        'niebieska ',
        707,
      ],
      [
        '1000507',
        '1002',
        52.0470801,
        20.8729945,
        'NFC1000507',
        '172',
        'Kabina Polska Umywalka',
        '',
        35,
      ],
      [
        '1000508',
        '1002',
        52.5228011,
        21.0603875,
        'NFC1000508',
        '236',
        'Kabina Polska Standard',
        '',
        630,
      ],
      [
        '1000513',
        '1000',
        52.3733549,
        21.0313653,
        'NFC1000513',
        '105/554',
        'Kabina Polska Standard',
        '',
        267,
      ],
      [
        '1000516',
        '1002',
        52.3154895,
        21.0602865,
        'NFC1000516',
        '219',
        'Kabina Polska Standard',
        '',
        384,
      ],
      [
        '1000517',
        '1002',
        52.0040718,
        20.9049596,
        'NFC1000517',
        'Z119',
        'Kabina Polska Standard',
        'wyblakla ',
        438,
      ],
      [
        '1000518',
        '1002',
        52.1968591,
        21.5746331,
        'NFC1000518',
        '015',
        'Kabina Polska Standard',
        '',
        132,
      ],
      [
        '1000519',
        '1002',
        52.2838068,
        21.1328302,
        'NFC1000519',
        '434',
        'Kabina Niemiecka Standard',
        'niebieska ubrudzona betonem ',
        82,
      ],
      [
        '1000520',
        '1000',
        52.3733239,
        21.0312745,
        'NFC1000520',
        'z25',
        'Kabina Polska Standard',
        '',
        124,
      ],
      [
        '1000521',
        '1002',
        52.1768381,
        21.2474227,
        'NFC1000521',
        '010/474',
        'Kabina Włoska Standard',
        '',
        115,
      ],
      [
        '1000522',
        '1002',
        52.341569,
        20.9638111,
        'NFC1000522',
        '225',
        'Kabina Polska Standard',
        '',
        117,
      ],
      [
        '1000524',
        '1002',
        52.2122416,
        21.047322,
        'NFC1000524',
        'Z26',
        'Kabina Włoska Standard',
        '',
        223,
      ],
      [
        '1000527',
        '1002',
        52.1333111,
        21.3460796,
        'NFC1000527',
        '162',
        'Kabina Włoska Standard',
        '',
        716,
      ],
      [
        '1000528',
        '1002',
        52.1082394,
        21.1036596,
        'NFC1000528',
        '302',
        'Kabina Niemiecka Standard',
        '',
        216,
      ],
      [
        '1000529',
        '1002',
        52.3370431,
        21.0437358,
        'NFC1000529',
        '363',
        'Kabina Niemiecka Standard',
        'niebieska',
        429,
      ],
      [
        '1000530',
        '1002',
        52.0611535,
        20.863224,
        'NFC1000530',
        '068',
        'Kabina Polska Standard',
        '',
        171,
      ],
      [
        '1000531',
        '1002',
        52.3092636,
        21.1045315,
        'NFC1000531',
        'Z104',
        'Kabina Niemiecka Standard',
        '',
        266,
      ],
      [
        '1000532',
        '1002',
        52.0605552,
        20.8602635,
        'NFC1000532',
        '136',
        'Kabina Polska Standard',
        '',
        576,
      ],
      [
        '1000533',
        '1002',
        52.059966,
        20.8616396,
        'NFC1000533',
        '047/209',
        'Kabina Polska Standard',
        '',
        632,
      ],
      [
        '1000551',
        '1002',
        52.1257192,
        21.4748679,
        'NFC1000551',
        'Z27',
        'Kabina Włoska Standard',
        '',
        138,
      ],
      [
        '1000552',
        '1002',
        52.3798558,
        21.1511292,
        'NFC1000552',
        '182',
        'Kabina Polska Standard',
        '',
        178,
      ],
      [
        '1000553',
        '1002',
        52.2052963,
        21.1530096,
        'NFC1000553',
        'Z28',
        'Kabina Polska Standard',
        '',
        293,
      ],
      [
        '1000557',
        '1002',
        52.2348924,
        21.1570057,
        'NFC1000557',
        'R185',
        'Kabina Polska Standard',
        '',
        174,
      ],
      [
        '1000558',
        '1002',
        52.5298478,
        21.0112263,
        'NFC1000558',
        '023',
        'Kabina Niemiecka Uwywalka',
        '',
        353,
      ],
      [
        '1000559',
        '1002',
        52.1825506,
        21.0617265,
        'NFC1000559',
        '073',
        'Kabina Niemiecka Uwywalka',
        '',
        554,
      ],
      [
        '1000560',
        '1002',
        52.2707658,
        21.0592817,
        'NFC1000560',
        '39',
        'Kabina Polska Umywalka',
        'bez baniaka',
        118,
      ],
      [
        '1000561',
        '1002',
        52.2319946,
        21.0605434,
        'NFC1000561',
        '119Z ',
        'Kabina Niemiecka Standard',
        'saska parking ',
        111,
      ],
      [
        '1000562',
        '1002',
        52.442307,
        21.1195666,
        'NFC1000562',
        '016',
        'Kabina Niemiecka Standard',
        '',
        261,
      ],
      [
        '1000563',
        '1002',
        52.4754783,
        20.9827197,
        'NFC1000563',
        '143',
        'Kabina Niemiecka Standard',
        '',
        303,
      ],
      [
        '1000564',
        '1002',
        52.2058756,
        21.1405449,
        'NFC1000564',
        '231',
        'Kabina Polska Umywalka',
        '',
        522,
      ],
      [
        '1000565',
        '1002',
        52.3640567,
        21.0069091,
        'NFC1000565',
        '141',
        'Kabina Włoska Standard',
        '',
        122,
      ],
      [
        '1000566',
        '1002',
        52.4014316,
        20.9670988,
        'NFC1000566',
        'R218',
        'Kabina Niemiecka Standard',
        '',
        306,
      ],
      [
        '1000567',
        '1002',
        52.2831126,
        20.9013106,
        'NFC1000567',
        'Z29',
        'Kabina Niemiecka Uwywalka',
        'niebieska ',
        412,
      ],
      [
        '1000568',
        '1002',
        52.4870453,
        20.9979846,
        'NFC1000568',
        '54',
        'Kabina Polska Standard',
        '',
        245,
      ],
      [
        '1000569',
        '1002',
        52.3113007,
        21.0783708,
        'NFC1000569',
        '330',
        'Kabina Niemiecka Standard',
        '',
        381,
      ],
      [
        '1000570',
        '1002',
        52.4243756,
        20.8862902,
        'NFC1000570',
        'Z30 ',
        'Kabina Polska Standard',
        '',
        214,
      ],
      [
        '1000571',
        '1002',
        52.372228,
        20.9262996,
        'NFC1000571',
        '486',
        'Kabina Włoska Standard',
        '',
        575,
      ],
      [
        '1000572',
        '1002',
        52.3134385,
        21.0874194,
        'NFC1000572',
        'Z31 ',
        'Kabina Niemiecka Standard',
        '',
        297,
      ],
      [
        '1000573',
        '1002',
        52.5199453,
        21.0552412,
        'NFC1000573',
        'Z32 ',
        'Kabina Niemiecka Standard',
        '',
        148,
      ],
      [
        '1000574',
        '1002',
        52.2089224,
        21.0910145,
        'NFC1000574',
        '031/351',
        'Kabina Polska Standard',
        '',
        496,
      ],
      [
        '1000575',
        '1002',
        52.5532346,
        21.1699914,
        'NFC1000575',
        'R053',
        'Kabina Polska Standard',
        '',
        345,
      ],
      [
        '1000581',
        '1002',
        52.3962407,
        21.0077247,
        'NFC1000581',
        '158',
        'Kabina Polska Standard',
        '1 P72',
        561,
      ],
      [
        '1000582',
        '1002',
        52.065654,
        20.9938712,
        'NFC1000582',
        '252',
        'Kabina Polska Standard',
        '',
        625,
      ],
      [
        '1000583',
        '1002',
        52.0677704,
        21.0810769,
        'NFC1000583',
        '174',
        'Kabina Polska Standard',
        '',
        18,
      ],
      [
        '1000584',
        '1002',
        52.1816163,
        20.5514769,
        'NFC1000584',
        '408 ',
        'Kabina Niemiecka Standard',
        '',
        118,
      ],
      [
        '1000589',
        '1002',
        51.9800991,
        21.020737,
        'NFC1000589',
        '390',
        'Kabina Niemiecka Standard',
        'niebieska',
        424,
      ],
      [
        '1000590',
        '1000',
        52.3731694,
        21.0302779,
        'NFC1000590',
        '060',
        'Kabina Polska Standard',
        '',
        76,
      ],
      [
        '1000591',
        '1002',
        52.049827,
        21.001542,
        'NFC1000591',
        'Z33',
        'Kabina Polska Standard',
        '',
        250,
      ],
      [
        '1000592',
        '1002',
        51.8822187,
        20.8434913,
        'NFC1000592',
        '144',
        'Kabina Niemiecka Standard',
        '',
        636,
      ],
      [
        '1000593',
        '1002',
        52.0978745,
        21.0170271,
        'NFC1000593',
        '149',
        'Kabina Polska Standard',
        '',
        665,
      ],
      [
        '1000594',
        '1002',
        52.4329313,
        20.9671598,
        'NFC1000594',
        '148 (DUPLIKAT)',
        'Kabina Niemiecka Standard',
        '',
        258,
      ],
      [
        '1000595',
        '1002',
        52.0135044,
        21.1407009,
        'NFC1000595',
        '202',
        'Kabina Polska Standard',
        '',
        236,
      ],
      [
        '1000596',
        '1002',
        52.4387122,
        20.7809444,
        'NFC1000596',
        '077 (DUPLIKAT)',
        'Kabina Niemiecka Uwywalka',
        'zielona',
        135,
      ],
      [
        '1000597',
        '1002',
        52.393658,
        20.9314882,
        'NFC1000597',
        '007',
        'Kabina Niemiecka Standard',
        '',
        480,
      ],
      [
        '1000598',
        '1002',
        52.1145005,
        21.2951444,
        'NFC1000598',
        '388',
        'Kabina Włoska Umywalka',
        '',
        32,
      ],
      [
        '1000603',
        '1002',
        52.0686921,
        20.5910724,
        'NFC1000603',
        '33',
        'Kabina Polska Umywalka',
        '',
        240,
      ],
      [
        '1000608',
        '1002',
        52.0437099,
        20.9519322,
        'NFC1000608',
        '087',
        'Kabina Polska Standard',
        '',
        29,
      ],
      [
        '1000609',
        '1002',
        52.2820706,
        21.0573549,
        'NFC1000609',
        'Z34',
        'Kabina Włoska Standard',
        '',
        278,
      ],
      [
        '1000610',
        '1002',
        52.3171054,
        21.1253106,
        'NFC1000610',
        '180',
        'Kabina Polska Standard',
        'uszkodzony prawy narożnik ',
        55,
      ],
      [
        '1000621',
        '1002',
        52.2003261,
        20.5790755,
        'NFC1000621',
        'Z35b',
        'Kabina Niemiecka Standard',
        '',
        221,
      ],
      [
        '1000622',
        '1000',
        52.3730624,
        21.0302684,
        'NFC1000622',
        '023/240',
        'Kabina Niemiecka Standard',
        '',
        254,
      ],
      [
        '1000623',
        '1000',
        52.3731207,
        21.0302963,
        'NFC1000623',
        'Z36',
        'Kabina Niemiecka Standard',
        '',
        670,
      ],
      [
        '1000624',
        '1002',
        52.0814169,
        21.1007342,
        'NFC1000624',
        '405',
        'Kabina Polska Standard',
        '',
        572,
      ],
      [
        '1000626',
        '1002',
        52.3081784,
        21.0311043,
        'NFC1000626',
        '247',
        'Kabina Polska Standard',
        '',
        417,
      ],
      [
        '1000645',
        '1002',
        52.2761918,
        20.9519893,
        'NFC1000645',
        '359',
        '',
        '',
        540,
      ],
      [
        '1000680',
        '1002',
        52.3891866,
        20.9080339,
        'NFC1000680',
        '125',
        '',
        '',
        680,
      ],
      [
        '1000699',
        '1002',
        52.2464594,
        21.2181028,
        'NFC1000699',
        '261',
        '',
        '',
        362,
      ],
      [
        '1000707',
        '1002',
        52.4438816,
        21.2920012,
        'NFC1000707',
        '084',
        '',
        '',
        673,
      ],
      [
        '1000748',
        '1002',
        52.2761003,
        21.0896994,
        'NFC1000748',
        'R12',
        '',
        '',
        507,
      ],
      [
        '1000763',
        '1002',
        52.3661524,
        21.042116,
        'NFC1000763',
        'R17',
        '',
        '',
        353,
      ],
      [
        '1000770',
        '1002',
        52.4234467,
        20.9304227,
        'NFC1000770',
        'R19',
        '',
        '',
        466,
      ],
      [
        '1000794',
        '1000',
        52.3730474,
        21.0301535,
        'NFC1000794',
        'R22',
        '',
        '',
        366,
      ],
      [
        '1000830',
        '1002',
        52.1157268,
        20.6289307,
        'NFC1000830',
        '025',
        '',
        '',
        325,
      ],
      [
        '1000847',
        '1002',
        52.3246521,
        20.8854534,
        'NFC1000847',
        'b35',
        '',
        '',
        177,
      ],
      [
        '1000854',
        '1000',
        52.3729577,
        21.0304483,
        'NFC1000854',
        '412',
        '',
        '',
        485,
      ],
      [
        '1000865',
        '1002',
        52.314945,
        21.1209555,
        'NFC1000865',
        'B38 (DUPLIKAT)',
        '',
        'niebieska',
        378,
      ],
      [
        '1000866',
        '1002',
        52.3249225,
        21.0486654,
        'NFC1000866',
        'B39 (DUPLIKAT)',
        'Kabina Niemiecka Standard',
        'niebieska',
        427,
      ],
      [
        '1000867',
        '1002',
        52.350696,
        20.8357626,
        'NFC1000867',
        'B40',
        'Kabina Niemiecka Standard',
        'niebieska',
        415,
      ],
      [
        '1000868',
        '1002',
        52.3394907,
        21.0653154,
        'NFC1000868',
        'B43',
        'Kabina Niemiecka Standard',
        'niebieska',
        444,
      ],
      [
        '1000869',
        '1002',
        52.3539958,
        20.9405557,
        'NFC1000869',
        'B42',
        'Kabina Niemiecka Standard',
        'niebieska',
        81,
      ],
      [
        '1000870',
        '1002',
        52.3827754,
        21.0291037,
        'NFC1000870',
        'B41',
        'Kabina Niemiecka Standard',
        'niemiecka',
        319,
      ],
      [
        '1000871',
        '1002',
        52.184273,
        21.1547952,
        'NFC1000871',
        'p01',
        'Kabina Niemiecka Standard',
        '',
        632,
      ],
      [
        '1000872',
        '1002',
        52.421851,
        21.1713338,
        'NFC1000872',
        'B44',
        'Kabina Niemiecka Standard',
        '',
        481,
      ],
      [
        '1000873',
        '1002',
        52.2299687,
        20.9206358,
        'NFC1000873',
        'Z210',
        'Kabina Włoska Standard',
        '',
        485,
      ],
      [
        '1000874',
        '1002',
        52.3333826,
        21.0003541,
        'NFC1000874',
        '556',
        'Kabina Polska Standard',
        '',
        611,
      ],
      [
        '1000878',
        '1002',
        52.2557178,
        21.3416291,
        'NFC1000878',
        'Z221',
        'Kabina Polska Standard',
        'kabina w budynku ',
        505,
      ],
      [
        '1000882',
        '1002',
        52.268425,
        20.7976753,
        'NFC1000882',
        '148',
        'Kabina Włoska Standard',
        '',
        515,
      ],
      [
        '1000887',
        '1002',
        52.0990776,
        21.282408,
        'NFC1000887',
        'b3636',
        'Kabina Niemiecka Standard',
        'niebieska',
        357,
      ],
      [
        '1000888',
        '1002',
        52.5343486,
        21.0372798,
        'NFC1000888',
        'b4343',
        'Kabina Niemiecka Standard',
        'niebieska',
        552,
      ],
      [
        '1000889',
        '1002',
        52.4175047,
        21.1601637,
        'NFC1000889',
        'b4242',
        'Kabina Niemiecka Standard',
        'niebieska',
        463,
      ],
      [
        '1000890',
        '1002',
        52.3516048,
        21.10624,
        'NFC1000890',
        'b3737',
        'Kabina Niemiecka Standard',
        'niebieska',
        240,
      ],
      [
        '1000891',
        '1002',
        51.924764,
        20.9081519,
        'NFC1000891',
        'b4040',
        'Kabina Niemiecka Standard',
        'niebieska',
        163,
      ],
      [
        '1000892',
        '1002',
        52.1065137,
        20.6521571,
        'NFC1000892',
        'b38',
        'Kabina Niemiecka Standard',
        'niebieska',
        247,
      ],
      [
        '1000893',
        '1002',
        52.4763503,
        21.0311789,
        'NFC1000893',
        'b39',
        'Kabina Niemiecka Standard',
        'niebieska',
        259,
      ],
      [
        '1000894',
        '1002',
        52.4205277,
        21.156173,
        'NFC1000894',
        'b41 (DUPLIKAT)',
        'Kabina Niemiecka Standard',
        'niebieska',
        661,
      ],
      [
        '1000895',
        '1002',
        52.4491776,
        20.6915844,
        'NFC1000895',
        'b45',
        'Kabina Niemiecka Uwywalka',
        'zielona',
        692,
      ],
      [
        '1000896',
        '1002',
        52.0473578,
        20.9838823,
        'NFC1000896',
        '155/367',
        'Kabina Polska Standard',
        'nabite dwa numery',
        205,
      ],
      [
        '1000897',
        '1002',
        52.2923257,
        20.9941484,
        'NFC1000897',
        'b072',
        'Kabina Polska Standard',
        '',
        216,
      ],
      [
        '1000898',
        '1002',
        52.4719999,
        20.7261862,
        'NFC1000898',
        '021',
        'Kabina Polska Standard',
        '',
        318,
      ],
      [
        '1000918',
        '1002',
        52.3968247,
        21.1724855,
        'NFC1000918',
        'b46',
        'Kabina Niemiecka Standard',
        'niebieska',
        314,
      ],
      [
        '1000919',
        '1002',
        52.210405,
        21.0822784,
        'NFC1000919',
        'b47',
        'Kabina Niemiecka Standard',
        'niebieska',
        250,
      ],
      [
        '1000920',
        '1002',
        52.4509377,
        21.2783615,
        'NFC1000920',
        'b48',
        'Kabina Niemiecka Standard',
        'niebieska',
        522,
      ],
      [
        '1000921',
        '1002',
        52.1910995,
        20.9740495,
        'NFC1000921',
        'b49',
        'Kabina Niemiecka Standard',
        'niebieska',
        713,
      ],
      [
        '1000922',
        '1002',
        52.1200037,
        21.2598311,
        'NFC1000922',
        'b50',
        'Kabina Niemiecka Standard',
        'niebieska',
        274,
      ],
      [
        '1000926',
        '1002',
        52.4102056,
        21.2038719,
        'NFC1000926',
        '135',
        'Kabina Niemiecka Standard',
        'Oblany smoła lub lepik ',
        682,
      ],
      [
        '1000927',
        '1002',
        52.0003839,
        21.0829626,
        'NFC1000927',
        '096',
        'Kabina Niemiecka Standard',
        '',
        174,
      ],
      [
        '1000928',
        '1002',
        52.3525847,
        20.8612739,
        'NFC1000928',
        '128/93',
        'Kabina Niemiecka Uwywalka',
        'umywalka zielona',
        273,
      ],
      [
        '1000929',
        '1002',
        52.0889268,
        20.9493283,
        'NFC1000929',
        'R32',
        'Kabina Polska Standard',
        '',
        621,
      ],
      [
        '1000930',
        '1002',
        52.4004738,
        20.8962035,
        'NFC1000930',
        'Z207',
        'Kabina Polska Standard',
        '',
        708,
      ],
      [
        '1000933',
        '1002',
        52.3083222,
        21.0319086,
        'NFC1000933',
        'p35\\n',
        'Kabina Włoska Standard',
        '',
        358,
      ],
      [
        '1000934',
        '1002',
        52.1841337,
        21.1582546,
        'NFC1000934',
        'b51',
        'Kabina Niemiecka Standard',
        'niebieska',
        273,
      ],
      [
        '1000935',
        '1002',
        52.0458182,
        20.8895109,
        'NFC1000935',
        'b52',
        'Kabina Niemiecka Standard',
        'niebieska',
        297,
      ],
      [
        '1000936',
        '1002',
        52.1886582,
        20.7571997,
        'NFC1000936',
        'l103',
        'Kabina Niemiecka Standard',
        '',
        170,
      ],
      [
        '1000937',
        '1002',
        52.2343698,
        21.3217702,
        'NFC1000937',
        'R33',
        'Kabina Niemiecka Standard',
        'niebieska',
        430,
      ],
      [
        '1000939',
        '1009',
        52.297821,
        21.0322832,
        'NFC1000939',
        'p36',
        'Kabina Niemiecka Standard',
        '',
        216,
      ],
      [
        '1000941',
        '1002',
        52.112616,
        21.235093,
        'NFC1000941',
        'b53',
        'Kabina Niemiecka Standard',
        'niebieska',
        570,
      ],
      [
        '1000942',
        '1002',
        52.1863704,
        20.9749072,
        'NFC1000942',
        'b54',
        'Kabina Niemiecka Standard',
        'niebieska',
        356,
      ],
      [
        '1000943',
        '1002',
        52.4111548,
        20.8762316,
        'NFC1000943',
        'b55',
        'Kabina Niemiecka Standard',
        'niebieska',
        378,
      ],
      [
        '1000944',
        '1002',
        52.2410749,
        21.1628845,
        'NFC1000944',
        'b56',
        'Kabina Niemiecka Niepełnosprawna',
        'zielona',
        647,
      ],
      [
        '1000945',
        '1002',
        52.1617793,
        20.9082769,
        'NFC1000945',
        'b57',
        'Kabina Niemiecka Standard',
        'niebieska',
        411,
      ],
      [
        '1000946',
        '1002',
        52.2411788,
        20.9453351,
        'NFC1000946',
        'b58',
        'Kabina Niemiecka Standard',
        'niemiecka',
        344,
      ],
      [
        '1000947',
        '1002',
        52.2727519,
        21.0482465,
        'NFC1000947',
        'b59',
        'Kabina Niemiecka Niepełnosprawna',
        'zielona',
        681,
      ],
      [
        '1000948',
        '1002',
        52.3575902,
        21.2435249,
        'NFC1000948',
        'b60',
        'Kabina Niemiecka Standard',
        'niebieska',
        160,
      ],
      [
        '1000949',
        '1002',
        52.4392848,
        20.6731468,
        'NFC1000949',
        '115',
        'Kabina Polska Standard',
        'wyblakła',
        699,
      ],
      [
        '1000950',
        '1002',
        52.0369226,
        20.8421516,
        'NFC1000950',
        'R34',
        'Kabina Niemiecka Standard',
        'niebieska',
        297,
      ],
      [
        '1000951',
        '1002',
        52.4008029,
        20.9082235,
        'NFC1000951',
        'p38',
        'Kabina Niemiecka Standard',
        '',
        188,
      ],
      [
        '1000952',
        '1002',
        52.0025102,
        21.0384333,
        'NFC1000952',
        'R35',
        'Kabina Niemiecka Standard',
        'Niebieska',
        152,
      ],
      [
        '1000954',
        '1002',
        52.3740457,
        21.1607505,
        'NFC1000954',
        'p39',
        'Kabina Niemiecka Uwywalka',
        '',
        215,
      ],
      [
        '1000955',
        '1002',
        52.2868291,
        21.1705888,
        'NFC1000955',
        '506',
        'Kabina Polska Standard',
        '',
        77,
      ],
      [
        '1000957',
        '1002',
        52.3633433,
        20.8518459,
        'NFC1000957',
        'Z59',
        'Kabina Niemiecka Standard',
        '',
        529,
      ],
      [
        '1000958',
        '1002',
        52.4009347,
        20.8145726,
        'NFC1000958',
        'R36',
        'Kabina Niemiecka Standard',
        'niebieska',
        26,
      ],
      [
        '1000959',
        '1002',
        52.372578,
        21.2148547,
        'NFC1000959',
        'p40',
        'Kabina Niemiecka Standard',
        '',
        702,
      ],
      [
        '1000963',
        '1000',
        52.329876,
        21.0301649,
        'NFC1000963',
        'p41',
        'Kabina Niemiecka Standard',
        '',
        326,
      ],
      [
        '1000964',
        '1002',
        52.0819037,
        21.1015945,
        'NFC1000964',
        'Z60',
        'Kabina Włoska Standard',
        '',
        57,
      ],
      [
        '1000966',
        '1002',
        52.4773361,
        21.1061796,
        'NFC1000966',
        '090/509',
        'Kabina Polska Standard',
        '',
        91,
      ],
      [
        '1000967',
        '1002',
        52.2753453,
        21.1567385,
        'NFC1000967',
        'p42\\n',
        'Kabina Włoska Standard',
        '',
        272,
      ],
      [
        '1000968',
        '1002',
        52.1620869,
        20.9377092,
        'NFC1000968',
        'R37',
        'Kabina Niemiecka Standard',
        'niebieska',
        72,
      ],
      [
        '1000969',
        '1002',
        52.0960329,
        20.9630406,
        'NFC1000969',
        '244',
        'Kabina Polska Standard',
        '',
        366,
      ],
      [
        '1000970',
        '1002',
        52.3951276,
        20.8941507,
        'NFC1000970',
        'Z044',
        'Kabina Polska Standard',
        '',
        116,
      ],
      [
        '1000971',
        '1002',
        52.2301287,
        20.8413525,
        'NFC1000971',
        '483',
        'Kabina Niemiecka Standard',
        'niebieska UMYWALKA!',
        671,
      ],
      [
        '1000972',
        '1000',
        52.3731947,
        21.0313959,
        'NFC1000972',
        '040',
        'Kabina Polska Standard',
        '',
        180,
      ],
      [
        '1000973',
        '1002',
        52.2421922,
        20.8806413,
        'NFC1000973',
        '169',
        'Kabina Polska Standard',
        '',
        335,
      ],
      [
        '1000974',
        '1002',
        52.0658281,
        20.6372891,
        'NFC1000974',
        '233',
        'Kabina Polska Standard',
        '',
        518,
      ],
      [
        '1000976',
        '1002',
        52.4257082,
        20.9739629,
        'NFC1000976',
        'R38',
        'Kabina Polska Standard',
        '',
        67,
      ],
      [
        '1000979',
        '1002',
        52.4778835,
        21.005014,
        'NFC1000979',
        '263',
        'Kabina Polska Standard',
        '',
        70,
      ],
      [
        '1000982',
        '1002',
        52.2441668,
        20.8054691,
        'NFC1000982',
        'R39',
        'Kabina Niemiecka Standard',
        'niebieska',
        45,
      ],
      [
        '1000983',
        '1002',
        52.477058,
        21.0234833,
        'NFC1000983',
        'R40',
        'Kabina Niemiecka Standard',
        'niebieska',
        28,
      ],
      [
        '1000986',
        '1002',
        52.3761242,
        20.9400961,
        'NFC1000986',
        '129 ',
        'Kabina Niemiecka Standard',
        '',
        431,
      ],
      [
        '1000987',
        '1002',
        52.3859203,
        20.9840929,
        'NFC1000987',
        'R41',
        'Kabina Niemiecka Standard',
        'niebieska',
        704,
      ],
      [
        '1000989',
        '1002',
        51.9625575,
        20.9534888,
        'NFC1000989',
        'b61',
        'Kabina Niemiecka Standard',
        'niebieska',
        334,
      ],
      [
        '1000990',
        '1002',
        52.1984686,
        20.7884392,
        'NFC1000990',
        'b62',
        'Kabina Niemiecka Standard',
        'niebieska',
        336,
      ],
      [
        '1000991',
        '1002',
        52.1317156,
        21.4364687,
        'NFC1000991',
        'b63',
        'Kabina Niemiecka Standard',
        'niebieska',
        690,
      ],
      [
        '1000992',
        '1002',
        52.3010467,
        21.1018415,
        'NFC1000992',
        'b64',
        'Kabina Niemiecka Standard',
        'niebieska',
        477,
      ],
      [
        '1000993',
        '1002',
        52.1638458,
        20.8883374,
        'NFC1000993',
        'b65',
        'Kabina Niemiecka Standard',
        'niebieska',
        62,
      ],
      [
        '1000994',
        '1002',
        52.3636798,
        21.1311048,
        'NFC1000994',
        'b66',
        'Kabina Niemiecka Standard',
        'niemiecka',
        448,
      ],
      [
        '1000995',
        '1002',
        52.1933775,
        21.4372406,
        'NFC1000995',
        'b67',
        'Kabina Niemiecka Standard',
        'niebieska',
        390,
      ],
      [
        '1000996',
        '1002',
        52.3415556,
        21.0231423,
        'NFC1000996',
        'b68',
        'Kabina Niemiecka Standard',
        'niebieska',
        162,
      ],
      [
        '1000997',
        '1002',
        52.4311401,
        21.09747,
        'NFC1000997',
        'b69',
        'Kabina Niemiecka Standard',
        'niebieska',
        582,
      ],
      [
        '1000998',
        '1002',
        52.2967727,
        21.0508663,
        'NFC1000998',
        'b70',
        'Kabina Niemiecka Standard',
        'niebieska',
        179,
      ],
      [
        '1000999',
        '1000',
        52.3730871,
        21.0302123,
        'NFC1000999',
        '190',
        'Kabina Polska Umywalka',
        'nie ma baniaka \\n',
        110,
      ],
      [
        '1001000',
        '1002',
        52.2101844,
        20.7891856,
        'NFC1001000',
        'R42',
        'Kabina Niemiecka Standard',
        'niebieska',
        461,
      ],
      [
        '1001001',
        '1002',
        52.3781941,
        20.9365322,
        'NFC1001001',
        '109',
        'Kabina Niemiecka Standard',
        'niebieska',
        698,
      ],
      [
        '1001004',
        '1002',
        52.5315975,
        21.0130392,
        'NFC1001004',
        'w5',
        'Kabina Polska Standard',
        'niemiecka umywalka zielona',
        306,
      ],
      [
        '1001005',
        '1002',
        52.0371908,
        20.8464532,
        'NFC1001005',
        'w6',
        'Kabina Niemiecka Standard',
        '',
        449,
      ],
      [
        '1001008',
        '1002',
        51.8489856,
        20.7432495,
        'NFC1001008',
        'w7',
        'Kabina Niemiecka Standard',
        '',
        678,
      ],
      [
        '1001009',
        '1002',
        52.3784303,
        20.9937146,
        'NFC1001009',
        '70',
        'Kabina Polska Standard',
        '',
        96,
      ],
      [
        '1001010',
        '1002',
        52.0455588,
        20.6364337,
        'NFC1001010',
        '489',
        'Kabina Niemiecka Standard',
        '',
        375,
      ],
      [
        '1001011',
        '1002',
        52.2322317,
        20.8204586,
        'NFC1001011',
        '504',
        'Kabina Polska Standard',
        '',
        483,
      ],
      [
        '1001012',
        '1002',
        52.4278434,
        20.8858346,
        'NFC1001012',
        '081',
        'Kabina Polska Standard',
        '',
        78,
      ],
      [
        '1001013',
        '1002',
        52.4143831,
        21.2025206,
        'NFC1001013',
        '205',
        'Kabina Polska Standard',
        '',
        540,
      ],
      [
        '1001014',
        '1002',
        52.2000888,
        21.0389649,
        'NFC1001014',
        '326',
        'Kabina Włoska Standard',
        '',
        525,
      ],
      [
        '1001015',
        '1002',
        52.3780584,
        21.020743,
        'NFC1001015',
        '497',
        'Kabina Niemiecka Standard',
        '',
        77,
      ],
      [
        '1001016',
        '1002',
        52.3366182,
        20.9877591,
        'NFC1001016',
        '107',
        'Kabina Niemiecka Standard',
        '',
        230,
      ],
      [
        '1001017',
        '1000',
        52.3729922,
        21.0302094,
        'NFC1001017',
        'w9',
        'Kabina Niemiecka Uwywalka',
        '',
        612,
      ],
      [
        '1001018',
        '1002',
        52.2646852,
        21.2971509,
        'NFC1001018',
        '795',
        'Kabina Polska Standard',
        '',
        636,
      ],
      [
        '1001019',
        '1002',
        52.4128021,
        21.1726611,
        'NFC1001019',
        'b27',
        'Kabina Włoska Standard',
        '',
        611,
      ],
      [
        '1001020',
        '1002',
        52.1390751,
        21.0379901,
        'NFC1001020',
        'w10',
        'Kabina Polska Standard',
        '',
        545,
      ],
      [
        '1001021',
        '1002',
        52.4138448,
        21.1708938,
        'NFC1001021',
        '028',
        'Kabina Polska Standard',
        '',
        409,
      ],
      [
        '1001022',
        '1002',
        52.238891,
        21.1084415,
        'NFC1001022',
        'w11',
        'Kabina Niemiecka Standard',
        '',
        55,
      ],
      [
        '1001023',
        '1002',
        52.1854766,
        21.0775649,
        'NFC1001023',
        '080360',
        'Kabina Polska Standard',
        '',
        201,
      ],
      [
        '1001024',
        '1002',
        52.144471,
        21.236515,
        'NFC1001024',
        '171357',
        'Kabina Polska Standard',
        '',
        398,
      ],
      [
        '1001025',
        '1000',
        52.3731263,
        21.0305336,
        'NFC1001025',
        'w12',
        'Kabina Włoska Umywalka',
        '',
        486,
      ],
      [
        '1001026',
        '1002',
        52.2080064,
        21.441146,
        'NFC1001026',
        '465',
        'Kabina Niemiecka Standard',
        '',
        322,
      ],
      [
        '1001027',
        '1002',
        52.0790836,
        21.1281306,
        'NFC1001027',
        '229',
        'Kabina Polska Standard',
        '',
        220,
      ],
      [
        '1001028',
        '1002',
        52.0358233,
        21.1274832,
        'NFC1001028',
        'w13',
        'Kabina Włoska Standard',
        '',
        433,
      ],
      [
        '1001029',
        '1002',
        51.7779793,
        20.7768491,
        'NFC1001029',
        '487',
        'Kabina Polska Standard',
        '',
        61,
      ],
      [
        '1001030',
        '1002',
        52.0316965,
        20.8761376,
        'NFC1001030',
        '333',
        'Kabina Polska Standard',
        '',
        217,
      ],
      [
        '1001036',
        '1002',
        52.3085161,
        20.9813724,
        'NFC1001036',
        '571',
        'Kabina Niemiecka Standard',
        '',
        344,
      ],
      [
        '1001037',
        '1002',
        52.0897454,
        20.7948754,
        'NFC1001037',
        '449',
        'Kabina Niemiecka Standard',
        '',
        229,
      ],
      [
        '1001038',
        '1002',
        52.2085598,
        21.1356902,
        'NFC1001038',
        '251',
        'Kabina Polska Standard',
        '',
        60,
      ],
      [
        '1001039',
        '1002',
        52.5056108,
        21.1276547,
        'NFC1001039',
        '66',
        'Kabina Polska Standard',
        '',
        220,
      ],
      [
        '1001040',
        '1002',
        52.5841015,
        20.8389763,
        'NFC1001040',
        '005',
        'Kabina Polska Standard',
        '',
        61,
      ],
      [
        '1001043',
        '1002',
        52.1925806,
        21.2198386,
        'NFC1001043',
        'w14',
        'Kabina Włoska Standard',
        '',
        158,
      ],
      [
        '1001044',
        '1002',
        52.3561455,
        20.8644077,
        'NFC1001044',
        'w15',
        'Kabina Niemiecka Standard',
        '',
        480,
      ],
      [
        '1001045',
        '1002',
        52.2745726,
        21.0490419,
        'NFC1001045',
        'w16',
        'Kabina Niemiecka Uwywalka',
        '',
        534,
      ],
      [
        '1001046',
        '1002',
        52.1614191,
        21.5597142,
        'NFC1001046',
        '0059',
        'Kabina Włoska Standard',
        '',
        410,
      ],
      [
        '1001048',
        '1002',
        52.1595243,
        21.2238486,
        'NFC1001048',
        '193',
        'Kabina Polska Standard',
        '',
        50,
      ],
      [
        '1001049',
        '1002',
        52.0741359,
        21.0221089,
        'NFC1001049',
        'w17',
        'Kabina Niemiecka Standard',
        '',
        429,
      ],
      [
        '1001050',
        '1002',
        52.3564784,
        21.0252567,
        'NFC1001050',
        'w18',
        'Kabina Niemiecka Standard',
        '',
        95,
      ],
      [
        '1001060',
        '1002',
        52.147648,
        21.1411038,
        'NFC1001060',
        'R43',
        'Kabina Niemiecka Standard',
        'niebieska',
        636,
      ],
      [
        '1001061',
        '1002',
        52.1990674,
        21.1455236,
        'NFC1001061',
        '224',
        'Kabina Polska Standard',
        '',
        39,
      ],
      [
        '1001062',
        '1002',
        52.2455274,
        21.2695181,
        'NFC1001062',
        'Z149',
        'Kabina Niemiecka Standard',
        '',
        231,
      ],
      [
        '1001063',
        '1002',
        52.1775214,
        21.0245275,
        'NFC1001063',
        'Z122',
        'Kabina Włoska Standard',
        '',
        637,
      ],
      [
        '1001064',
        '1002',
        52.2144811,
        21.2207204,
        'NFC1001064',
        'R116',
        'Kabina Niemiecka Standard',
        'niebieska',
        412,
      ],
      [
        '1001065',
        '1002',
        52.3318214,
        21.0524105,
        'NFC1001065',
        'Z62',
        'Kabina Niemiecka Uwywalka',
        '',
        217,
      ],
      [
        '1001066',
        '1002',
        52.3202005,
        20.9807022,
        'NFC1001066',
        'R44',
        'Kabina Niemiecka Standard',
        'niebieska',
        382,
      ],
      [
        '1001067',
        '1002',
        52.132536,
        20.9056476,
        'NFC1001067',
        'p44',
        'Kabina Niemiecka Standard',
        '',
        674,
      ],
      [
        '1001068',
        '1002',
        52.3171918,
        21.2734316,
        'NFC1001068',
        'b71 (DUPLIKAT)',
        'Kabina Niemiecka Standard',
        'niebieska',
        520,
      ],
      [
        '1001069',
        '1002',
        52.3377756,
        21.0491793,
        'NFC1001069',
        'b72',
        'Kabina Niemiecka Standard',
        'niemiecka',
        248,
      ],
      [
        '1001070',
        '1002',
        52.0459431,
        21.110208,
        'NFC1001070',
        'b74',
        'Kabina Niemiecka Standard',
        'niemiecka',
        317,
      ],
      [
        '1001071',
        '1002',
        52.3570244,
        21.1214499,
        'NFC1001071',
        'b73',
        'Kabina Niemiecka Standard',
        'niebieska',
        221,
      ],
      [
        '1001072',
        '1002',
        52.3871722,
        20.7480176,
        'NFC1001072',
        '559',
        'Kabina Polska Standard',
        '',
        623,
      ],
      [
        '1001073',
        '1002',
        52.3113424,
        21.0056174,
        'NFC1001073',
        '201',
        'Kabina Polska Umywalka',
        '',
        426,
      ],
      [
        '1001074',
        '1002',
        52.3707986,
        21.1521196,
        'NFC1001074',
        'p45',
        'Kabina Niemiecka Standard',
        '',
        433,
      ],
      [
        '1001075',
        '1002',
        52.4412936,
        20.8595436,
        'NFC1001075',
        '484',
        'Kabina Niemiecka Standard',
        '',
        477,
      ],
      [
        '1001076',
        '1002',
        52.2084848,
        21.3016128,
        'NFC1001076',
        '270',
        'Kabina Polska Standard',
        '',
        220,
      ],
      [
        '1001077',
        '1002',
        52.3089332,
        21.037965,
        'NFC1001077',
        '80L020',
        'Kabina Niemiecka Uwywalka',
        'zielona',
        698,
      ],
      [
        '1001078',
        '1002',
        52.1079311,
        21.1049635,
        'NFC1001078',
        'b71',
        'Kabina Polska Standard',
        '',
        541,
      ],
      [
        '1001079',
        '1002',
        52.5366133,
        21.101933,
        'NFC1001079',
        'R45',
        'Kabina Niemiecka Standard',
        'niebieska',
        490,
      ],
      [
        '1001080',
        '1002',
        52.3669053,
        21.0440454,
        'NFC1001080',
        '401',
        'Kabina Polska Standard',
        '',
        654,
      ],
      [
        '1001081',
        '1002',
        52.2775617,
        20.8729398,
        'NFC1001081',
        'p46',
        'Kabina Niemiecka Standard',
        '',
        19,
      ],
      [
        '1001082',
        '1002',
        52.4475242,
        20.9224305,
        'NFC1001082',
        'l070',
        'Kabina Niemiecka Standard',
        '',
        703,
      ],
      [
        '1001083',
        '1002',
        52.1843022,
        20.6031518,
        'NFC1001083',
        'R46',
        'Kabina Niemiecka Standard',
        'niebieska',
        488,
      ],
      [
        '1001084',
        '1002',
        52.2511915,
        20.7332425,
        'NFC1001084',
        'p47',
        'Kabina Niemiecka Standard',
        '',
        550,
      ],
      [
        '1001085',
        '1002',
        52.1649802,
        21.2458369,
        'NFC1001085',
        'p48m',
        'Kabina Włoska Standard',
        '',
        341,
      ],
      [
        '1001086',
        '1002',
        52.3548426,
        21.1227705,
        'NFC1001086',
        '300',
        'Kabina Polska Standard',
        '',
        401,
      ],
      [
        '1001087',
        '1002',
        52.4068634,
        21.3195053,
        'NFC1001087',
        '270 (DUPLIKAT)',
        'Kabina Polska Standard',
        '',
        232,
      ],
      [
        '1001088',
        '1002',
        52.1939251,
        20.8437459,
        'NFC1001088',
        'R47',
        'Kabina Polska Standard',
        '',
        460,
      ],
      [
        '1001089',
        '1002',
        52.3252314,
        20.9847376,
        'NFC1001089',
        'p49',
        'Kabina Niemiecka Standard',
        '\\n',
        79,
      ],
      [
        '1001090',
        '1002',
        52.3176405,
        21.1086417,
        'NFC1001090',
        'p50',
        'Kabina Polska Standard',
        '',
        299,
      ],
      [
        '1001091',
        '1002',
        52.1775126,
        21.5612969,
        'NFC1001091',
        '023a',
        'Kabina Polska Standard',
        '',
        538,
      ],
      [
        '1001092',
        '1002',
        52.1999518,
        20.6044197,
        'NFC1001092',
        'Z109',
        'Kabina Niemiecka Standard',
        '',
        167,
      ],
      [
        '1001112',
        '1002',
        52.4124672,
        20.8936095,
        'NFC1001112',
        '505',
        '',
        '',
        483,
      ],
      [
        '1001118',
        '1002',
        52.1904708,
        20.978001,
        'NFC1001118',
        'R50',
        '',
        '',
        656,
      ],
      [
        '1001120',
        '1002',
        52.0668177,
        21.1089504,
        'NFC1001120',
        'R51',
        '',
        '',
        614,
      ],
      [
        '1001157',
        '1002',
        52.3420082,
        20.9585666,
        'NFC1001157',
        'R56',
        '',
        '',
        398,
      ],
      [
        '1001165',
        '1002',
        52.2095695,
        20.9052008,
        'NFC1001165',
        'b104',
        '',
        '',
        162,
      ],
      [
        '1001192',
        '1002',
        52.1236817,
        21.2111927,
        'NFC1001192',
        'b107',
        '',
        '',
        294,
      ],
      [
        '1001202',
        '1002',
        52.1131789,
        21.1807158,
        'NFC1001202',
        'R65',
        '',
        '',
        559,
      ],
      [
        '1001208',
        '1002',
        52.2212211,
        21.0845349,
        'NFC1001208',
        'R68',
        '',
        '',
        459,
      ],
      [
        '1001211',
        '1000',
        52.3730525,
        21.0303237,
        'NFC1001211',
        '131',
        '',
        '',
        713,
      ],
      [
        '1001223',
        '1002',
        52.2401616,
        21.3332024,
        'NFC1001223',
        'w32',
        '',
        '',
        133,
      ],
      [
        '1001229',
        '1002',
        52.1237313,
        20.9660185,
        'NFC1001229',
        'p58',
        '',
        '',
        491,
      ],
      [
        '1001266',
        '1000',
        52.3729037,
        21.0294764,
        'NFC1001266',
        '127/112',
        '',
        '',
        129,
      ],
      [
        '1001275',
        '1002',
        52.4890757,
        21.4116348,
        'NFC1001275',
        'R78',
        '',
        '',
        452,
      ],
      [
        '1001282',
        '1002',
        51.7813546,
        20.7294257,
        'NFC1001282',
        'p68',
        '',
        '',
        288,
      ],
      [
        '1001353',
        '1002',
        52.0741215,
        20.9474438,
        'NFC1001353',
        'p73',
        '',
        '',
        289,
      ],
      [
        '1001361',
        '1002',
        52.386343,
        21.1870513,
        'NFC1001361',
        '168',
        '',
        '',
        576,
      ],
      [
        '1001417',
        '1002',
        52.4368324,
        21.0197012,
        'NFC1001417',
        '137',
        '',
        '',
        122,
      ],
      [
        '1001425',
        '1002',
        52.4501746,
        21.394132,
        'NFC1001425',
        'p78',
        '',
        '',
        300,
      ],
      [
        '1001458',
        '1000',
        52.3731657,
        21.0300189,
        'NFC1001458',
        'R100',
        '',
        '',
        61,
      ],
      [
        '1001510',
        '1002',
        52.459592,
        20.9007311,
        'NFC1001510',
        '301',
        '',
        '',
        248,
      ],
      [
        '1001522',
        '1000',
        52.3732214,
        21.0317042,
        'NFC1001522',
        'w46',
        '',
        '',
        260,
      ],
      [
        '1001528',
        '1002',
        52.2533641,
        21.2200236,
        'NFC1001528',
        'B133',
        '',
        '',
        168,
      ],
      [
        '1001555',
        '1002',
        52.2430821,
        20.8806417,
        'NFC1001555',
        'B144',
        '',
        '',
        314,
      ],
      [
        '1001567',
        '1002',
        52.3741512,
        20.7188828,
        'NFC1001567',
        'l045',
        '',
        '',
        675,
      ],
      [
        '1001583',
        '1002',
        52.4765922,
        20.9915642,
        'NFC1001583',
        '496',
        '',
        '',
        491,
      ],
      [
        '1001589',
        '1002',
        52.2980209,
        21.1056257,
        'NFC1001589',
        '085',
        '',
        '',
        418,
      ],
      [
        '1001613',
        '1002',
        52.3265645,
        21.100663,
        'NFC1001613',
        'b176',
        '',
        '',
        186,
      ],
      [
        '1001619',
        '1002',
        51.7640016,
        20.851999,
        'NFC1001619',
        'w52',
        '',
        '',
        114,
      ],
      [
        '1001632',
        '1002',
        52.2779919,
        21.1328765,
        'NFC1001632',
        'b189',
        '',
        '',
        434,
      ],
      [
        '1001638',
        '1002',
        52.0694737,
        20.8557058,
        'NFC1001638',
        'w53',
        '',
        '',
        96,
      ],
      [
        '1001656',
        '1002',
        52.2557979,
        21.4756111,
        'NFC1001656',
        'b198',
        '',
        '',
        99,
      ],
      [
        '1001714',
        '1002',
        52.312317,
        21.1097017,
        'NFC1001714',
        'B222',
        '',
        '',
        619,
      ],
      [
        '1001726',
        '1002',
        52.3384532,
        20.9417196,
        'NFC1001726',
        'B238',
        '',
        '',
        109,
      ],
      [
        '1001742',
        '1002',
        52.2872066,
        20.8126566,
        'NFC1001742',
        'R118',
        '',
        '',
        55,
      ],
      [
        '1001748',
        '1002',
        52.4328375,
        20.7261974,
        'NFC1001748',
        'R119',
        '',
        '',
        156,
      ],
      [
        '1001763',
        '1002',
        52.1158271,
        20.8473976,
        'NFC1001763',
        '334',
        '',
        '',
        215,
      ],
      [
        '1001769',
        '1002',
        52.1765805,
        21.2947724,
        'NFC1001769',
        'B256',
        '',
        '',
        190,
      ],
      [
        '1001787',
        '1002',
        52.4005964,
        20.9761637,
        'NFC1001787',
        'w600',
        '',
        '',
        698,
      ],
      [
        '1001802',
        '1002',
        52.0687773,
        20.5917987,
        'NFC1001802',
        'R123',
        '',
        '',
        448,
      ],
      [
        '1001808',
        '1002',
        52.3548032,
        21.2666894,
        'NFC1001808',
        'p219',
        '',
        '',
        126,
      ],
      [
        '1001847',
        '1002',
        52.2758687,
        21.3144583,
        'NFC1001847',
        'B269',
        '',
        '',
        562,
      ],
      [
        '1001882',
        '1002',
        52.442722,
        20.8404407,
        'NFC1001882',
        'B287',
        '',
        '',
        46,
      ],
      [
        '1001940',
        '1002',
        51.9226637,
        20.985744,
        'NFC1001940',
        'w075',
        '',
        '',
        129,
      ],
      [
        '1001978',
        '1000',
        52.3580719,
        21.0260425,
        'NFC1001978',
        'b336',
        '',
        '',
        146,
      ],
      [
        '1002020',
        '1002',
        52.0787915,
        20.6858099,
        'NFC1002020',
        'bb336',
        '',
        '',
        460,
      ],
      [
        '1002033',
        '1002',
        52.0717689,
        20.7855262,
        'NFC1002033',
        '570',
        '',
        '',
        676,
      ],
      [
        '1002057',
        '1002',
        52.3987685,
        21.1407551,
        'NFC1002057',
        'd01',
        '',
        '',
        485,
      ],
      [
        '1002092',
        '1002',
        52.0348299,
        20.6333984,
        'NFC1002092',
        'D04',
        '',
        '',
        217,
      ],
      [
        '1002237',
        '1002',
        52.13808,
        21.235836,
        'NFC1002237',
        'd17',
        '',
        '',
        305,
      ],
      [
        '1002240',
        '1002',
        52.260126,
        20.562914,
        'NFC1002240',
        'w67',
        '',
        '',
        261,
      ],
      [
        '1002259',
        '1002',
        52.0872167,
        21.3876938,
        'NFC1002259',
        'w68',
        '',
        '',
        485,
      ],
      [
        '1002308',
        '1002',
        52.3407934,
        21.0107807,
        'NFC1002308',
        'b375',
        '',
        '',
        706,
      ],
      [
        '1002323',
        '1002',
        52.3915178,
        21.2743921,
        'NFC1002323',
        'R161',
        '',
        '',
        543,
      ],
      [
        '1002403',
        '1002',
        52.3895104,
        21.309357,
        'NFC1002403',
        'R212',
        '',
        '',
        285,
      ],
      [
        '1002409',
        '1002',
        52.1642327,
        21.2446526,
        'NFC1002409',
        'R224',
        '',
        '',
        48,
      ],
      [
        '1002431',
        '1002',
        52.3938368,
        21.1866171,
        'NFC1002431',
        'R242',
        '',
        '',
        304,
      ],
      [
        '1002536',
        '1002',
        52.3317884,
        21.0014295,
        'NFC1002536',
        'B351',
        '',
        '',
        624,
      ],
      [
        '1002579',
        '1002',
        52.3336911,
        20.996524,
        'NFC1002579',
        'B407',
        '',
        '',
        543,
      ],
      [
        '1002597',
        '1002',
        52.4769158,
        21.03983,
        'NFC1002597',
        'B427',
        '',
        '',
        71,
      ],
      [
        '1002607',
        '1002',
        52.3216213,
        21.0546159,
        'NFC1002607',
        'B430',
        '',
        '',
        82,
      ],
      [
        '1002614',
        '1002',
        52.3137134,
        20.9746908,
        'NFC1002614',
        'B439',
        '',
        '',
        468,
      ],
      [
        '1002635',
        '1002',
        52.421586,
        21.2573247,
        'NFC1002635',
        'B461',
        '',
        '',
        213,
      ],
      [
        '1002642',
        '1000',
        52.373077,
        21.0302292,
        'NFC1002642',
        'B468',
        '',
        '',
        671,
      ],
      [
        '1002648',
        '1002',
        52.3555572,
        20.968338,
        'NFC1002648',
        'B472',
        '',
        '',
        288,
      ],
    ],
    photos: [
      {
        pointId: '1000484',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000484/pictures/PR1000_PT1000484_2019-03-27-14_52_12_4972873046898829492.jpg',
      },
      {
        pointId: '1000497',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000497/pictures/PR1000_PT1000497_2019-03-28-10_03_55_5792929426064907654.jpg',
      },
      {
        pointId: '1000503',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000503/pictures/PR1000_PT1000503_2019-03-28-11_23_51_115836915424533371.jpg',
      },
      {
        pointId: '1000503',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000503/pictures/PR1000_PT1000503_2019-03-28-11_24_08_7643301918827713515.jpg',
      },
      {
        pointId: '1000531',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000531/pictures/PR1000_PT1000531_2019-03-28-15_44_16_2222170022265335485.jpg',
      },
      {
        pointId: '1000567',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000567/pictures/PR1000_PT1000567_2019-03-29-10_56_10_5256873432733471465.jpg',
      },
      {
        pointId: '1000574',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000574/pictures/PR1000_PT1000574_2019-04-01-07_19_53_476664372429526989.jpg',
      },
      {
        pointId: '1000583',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000583/pictures/PR1000_PT1000583_2020-03-06-09_44_22_7879334514443402063.jpg',
      },
      {
        pointId: '1000589',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000589/pictures/PR1000_PT1000589_2019-04-01-09_57_01_7852179970894868135.jpg',
      },
      {
        pointId: '1000590',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000590/pictures/PR1000_PT1000590_2019-04-01-10_03_46_8816860613338010983.jpg',
      },
      {
        pointId: '1000645',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000645/pictures/PR1000_PT1000645_2019-04-02-09_47_24_3372174170404931898.jpg',
      },
      {
        pointId: '1000680',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000680/pictures/PR1000_PT1000680_2019-04-02-15_23_36_2740826705016719132.jpg',
      },
      {
        pointId: '1000680',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000680/pictures/PR1000_PT1000680_2019-04-02-15_23_53_7607249574562746164.jpg',
      },
      {
        pointId: '1000680',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000680/pictures/PR1000_PT1000680_2019-04-02-15_24_06_8431097057737623685.jpg',
      },
      {
        pointId: '1000699',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000699/pictures/PR1000_PT1000699_2019-04-03-10_57_01_3099759914080207768.jpg',
      },
      {
        pointId: '1000699',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000699/pictures/PR1000_PT1000699_2019-04-03-10_57_14_9023329724334264913.jpg',
      },
      {
        pointId: '1000699',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000699/pictures/PR1000_PT1000699_2019-04-03-10_57_27_5366744142681559877.jpg',
      },
      {
        pointId: '1000707',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000707/pictures/PR1000_PT1000707_2019-04-03-11_48_38_1234798798226063343.jpg',
      },
      {
        pointId: '1000748',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000748/pictures/PR1000_PT1000748_2019-04-04-10_08_16_2827954569754980462.jpg',
      },
      {
        pointId: '1000763',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000763/pictures/PR1000_PT1000763_2019-04-04-13_54_20_8798673012705627097.jpg',
      },
      {
        pointId: '1000770',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000770/pictures/PR1000_PT1000770_2019-04-05-07_03_15_8940091986218486999.jpg',
      },
      {
        pointId: '1000794',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000794/pictures/PR1000_PT1000794_2019-04-05-09_16_27_5910916732807208050.jpg',
      },
      {
        pointId: '1000830',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000830/pictures/PR1000_PT1000830_2019-04-08-11_27_17_6089937039534797250.jpg',
      },
      {
        pointId: '1000847',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000847/pictures/PR1000_PT1000847_2019-04-08-13_18_54_-327139131.jpg',
      },
      {
        pointId: '1000854',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000854/pictures/PR1000_PT1000854_2019-04-08-10_04_45_7368410015558866485.jpg',
      },
      {
        pointId: '1000866',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000866/pictures/PR1000_PT1000866_2019-04-09-08_23_10_4923492322343074188.jpg',
      },
      {
        pointId: '1000882',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000882/pictures/PR1000_PT1000882_2019-04-09-09_52_03_9074792780201004524.jpg',
      },
      {
        pointId: '1000882',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000882/pictures/PR1000_PT1000882_2019-04-09-09_52_11_7701132492446390500.jpg',
      },
      {
        pointId: '1000882',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000882/pictures/PR1000_PT1000882_2019-04-09-09_52_19_8739871675304767446.jpg',
      },
      {
        pointId: '1000888',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000888/pictures/PR1000_PT1000888_2019-04-08-16_32_45_-1975368407.jpg',
      },
      {
        pointId: '1000937',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000937/pictures/PR1000_PT1000937_2019-04-09-13_57_44_6251191685943986165.jpg',
      },
      {
        pointId: '1000959',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000959/pictures/PR1000_PT1000959_2019-04-10-08_51_43_8403420264386154504.jpg',
      },
      {
        pointId: '1000959',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1000959/pictures/PR1000_PT1000959_2019-04-10-08_51_51_9052278953935640031.jpg',
      },
      {
        pointId: '1001015',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001015/pictures/PR1000_PT1001015_2019-04-05-12_28_56_8425345322871983502.jpg',
      },
      {
        pointId: '1001027',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001027/pictures/PR1000_PT1001027_2019-04-08-11_28_42_3857058443080379217.jpg',
      },
      {
        pointId: '1001050',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001050/pictures/PR1000_PT1001050_2019-04-10-13_44_33_6096428505152279711.jpg',
      },
      {
        pointId: '1001068',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001068/pictures/PR1000_PT1001068_2019-04-11-08_32_27_451850061875384799.jpg',
      },
      {
        pointId: '1001071',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001071/pictures/PR1000_PT1001071_2019-04-11-08_36_18_1219896151041067937.jpg',
      },
      {
        pointId: '1001112',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001112/pictures/PR1000_PT1001112_2020-03-13-09_58_45_5617893118819220367.jpg',
      },
      {
        pointId: '1001112',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001112/pictures/PR1000_PT1001112_2020-03-13-09_58_58_314510895827144797.jpg',
      },
      {
        pointId: '1001118',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001118/pictures/PR1000_PT1001118_2019-04-12-07_40_19_5419529684205719088.jpg',
      },
      {
        pointId: '1001120',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001120/pictures/PR1000_PT1001120_2019-04-12-08_02_13_2036235796000994578.jpg',
      },
      {
        pointId: '1001157',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001157/pictures/PR1000_PT1001157_2019-04-12-12_52_20_5936908466983970091.jpg',
      },
      {
        pointId: '1001165',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001165/pictures/PR1000_PT1001165_2019-04-12-17_21_02_296117751.jpg',
      },
      {
        pointId: '1001192',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001192/pictures/PR1000_PT1001192_2019-04-15-14_42_50_1710128857.jpg',
      },
      {
        pointId: '1001202',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001202/pictures/PR1000_PT1001202_2019-04-16-09_48_45_2719921110719880026.jpg',
      },
      {
        pointId: '1001208',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001208/pictures/PR1000_PT1001208_2019-04-16-12_09_44_6141770367061714866.jpg',
      },
      {
        pointId: '1001211',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001211/pictures/PR1000_PT1001211_2019-04-16-12_46_47_2197270091373194575.jpg',
      },
      {
        pointId: '1001223',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001223/pictures/PR1000_PT1001223_2019-04-17-11_16_07_3935454056746571108.jpg',
      },
      {
        pointId: '1001229',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001229/pictures/PR1000_PT1001229_2019-04-17-13_23_18_3587438642628419785.jpg',
      },
      {
        pointId: '1001229',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001229/pictures/PR1000_PT1001229_2019-04-17-13_23_30_8044793736664248725.jpg',
      },
      {
        pointId: '1001229',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001229/pictures/PR1000_PT1001229_2019-04-17-13_23_42_3488376717694796458.jpg',
      },
      {
        pointId: '1001266',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001266/pictures/PR1000_PT1001266_2019-04-18-10_50_06_8665467111502794277.jpg',
      },
      {
        pointId: '1001275',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001275/pictures/PR1000_PT1001275_2019-04-19-06_31_19_4626864333817388790.jpg',
      },
      {
        pointId: '1001282',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001282/pictures/PR1000_PT1001282_2019-04-19-09_05_18_2360041107647735873.jpg',
      },
      {
        pointId: '1001282',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001282/pictures/PR1000_PT1001282_2019-04-19-09_05_30_5172674660566569552.jpg',
      },
      {
        pointId: '1001282',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001282/pictures/PR1000_PT1001282_2019-04-19-09_05_41_3495171076267841502.jpg',
      },
      {
        pointId: '1001353',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001353/pictures/PR1000_PT1001353_2019-04-24-10_21_56_3358214785021213136.jpg',
      },
      {
        pointId: '1001353',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001353/pictures/PR1000_PT1001353_2019-04-24-10_22_08_1154802557465386628.jpg',
      },
      {
        pointId: '1001361',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001361/pictures/PR1000_PT1001361_2019-04-24-11_40_34_223289641841856451.jpg',
      },
      {
        pointId: '1001417',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001417/pictures/PR1000_PT1001417_2019-04-26-10_45_48_6391994486386550584.jpg',
      },
      {
        pointId: '1001425',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001425/pictures/PR1000_PT1001425_2019-04-29-08_20_03_6177952092333594094.jpg',
      },
      {
        pointId: '1001425',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001425/pictures/PR1000_PT1001425_2019-04-29-08_20_15_874847121159848636.jpg',
      },
      {
        pointId: '1001425',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001425/pictures/PR1000_PT1001425_2019-04-29-08_20_24_2885407476102550290.jpg',
      },
      {
        pointId: '1001458',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001458/pictures/PR1000_PT1001458_2019-04-29-14_05_13_6180166048970310873.jpg',
      },
      {
        pointId: '1001510',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001510/pictures/PR1000_PT1001510_2019-05-06-08_41_56_7599806760049263499.jpg',
      },
      {
        pointId: '1001522',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001522/pictures/PR1000_PT1001522_2019-05-07-09_22_29_6550353512550175194.jpg',
      },
      {
        pointId: '1001528',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001528/pictures/PR1000_PT1001528_2019-05-02-10_17_00_4781978455916647630.jpg',
      },
      {
        pointId: '1001555',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001555/pictures/PR1000_PT1001555_2019-05-07-15_58_55_1958198369433990086.jpg',
      },
      {
        pointId: '1001567',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001567/pictures/PR1000_PT1001567_2019-05-09-07_54_25_1904000638800032401.jpg',
      },
      {
        pointId: '1001583',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001583/pictures/PR1000_PT1001583_2019-05-09-12_39_29_6248410585731203475.jpg',
      },
      {
        pointId: '1001589',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001589/pictures/PR1000_PT1001589_2019-05-13-13_10_38_5401199721292127101.jpg',
      },
      {
        pointId: '1001613',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001613/pictures/PR1000_PT1001613_2019-05-15-10_43_16_6315108362949187.jpg',
      },
      {
        pointId: '1001619',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001619/pictures/PR1000_PT1001619_2019-05-16-10_16_56_6116269089281469827.jpg',
      },
      {
        pointId: '1001632',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001632/pictures/PR1000_PT1001632_2019-05-18-14_40_42_5005928460033333062.jpg',
      },
      {
        pointId: '1001638',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001638/pictures/PR1000_PT1001638_2019-05-17-12_40_05_166922433978934640.jpg',
      },
      {
        pointId: '1001656',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001656/pictures/PR1000_PT1001656_2019-05-18-16_32_59_3492331841211336181.jpg',
      },
      {
        pointId: '1001714',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001714/pictures/PR1000_PT1001714_2019-05-29-10_04_43_3078628245945719833.jpg',
      },
      {
        pointId: '1001726',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001726/pictures/PR1000_PT1001726_2019-05-29-10_39_31_8053352330143683708.jpg',
      },
      {
        pointId: '1001742',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001742/pictures/PR1000_PT1001742_2019-06-03-08_44_20_3599129409229036315.jpg',
      },
      {
        pointId: '1001748',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001748/pictures/PR1000_PT1001748_2019-06-03-13_21_13_1269577019739673173.jpg',
      },
      {
        pointId: '1001763',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001763/pictures/PR1000_PT1001763_2019-06-05-09_30_23_3858868093334457137.jpg',
      },
      {
        pointId: '1001769',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001769/pictures/PR1000_PT1001769_2019-06-05-09_47_53_94389301594272081.jpg',
      },
      {
        pointId: '1001787',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001787/pictures/PR1000_PT1001787_2019-06-07-13_16_42_6620072200584816041.jpg',
      },
      {
        pointId: '1001802',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001802/pictures/PR1000_PT1001802_2019-06-11-07_47_01_8193394661636017765.jpg',
      },
      {
        pointId: '1001808',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001808/pictures/PR1000_PT1001808_2019-06-11-11_58_06_5720541592347056367.jpg',
      },
      {
        pointId: '1001847',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001847/pictures/PR1000_PT1001847_2019-06-22-11_36_29_2365124837028264021.jpg',
      },
      {
        pointId: '1001882',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001882/pictures/PR1000_PT1001882_2019-06-24-16_10_47_7956049494397969212.jpg',
      },
      {
        pointId: '1001940',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001940/pictures/PR1000_PT1001940_2019-06-27-11_00_47_5121931054005433782.jpg',
      },
      {
        pointId: '1001978',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1001978/pictures/PR1000_PT1001978_2019-07-01-11_55_05_8319793319265709498.jpg',
      },
      {
        pointId: '1002020',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1002020/pictures/PR1000_PT1002020_2019-07-09-12_50_36_9086528654628894343.jpg',
      },
      {
        pointId: '1002020',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1002020/pictures/PR1000_PT1002020_2019-07-09-12_52_31_3509018546537854528.jpg',
      },
      {
        pointId: '1002033',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1002033/pictures/PR1000_PT1002033_2019-07-11-07_37_41_365124672814212260.jpg',
      },
      {
        pointId: '1002057',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1002057/pictures/PR1000_PT1002054_2020-02-12-11_30_42_1375530574789392415.jpg',
      },
      {
        pointId: '1002057',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1002057/pictures/PR1000_PT1002057_2019-07-17-13_58_33_8786940123291879817.jpg',
      },
      {
        pointId: '1002092',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1002092/pictures/PR1000_PT1002092_2019-07-24-11_19_33_3805025433893619854.jpg',
      },
      {
        pointId: '1002237',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1002237/pictures/PR1000_PT1002237_2019-09-04-13_03_51_2495671033736930461.jpg',
      },
      {
        pointId: '1002240',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1002240/pictures/PR1000_PT1002240_2019-09-06-12_05_43_534776220802241898.jpg',
      },
      {
        pointId: '1002259',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1002259/pictures/PR1000_PT1002259_2019-09-20-12_37_20_2924217651775644306.jpg',
      },
      {
        pointId: '1002308',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1002308/pictures/PR1000_PT1002308_2019-12-03-15_38_30_6510834236416594720.jpg',
      },
      {
        pointId: '1002323',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1002323/pictures/PR1000_PT1002323_2020-01-27-07_21_09_1794839740440504332.jpg',
      },
      {
        pointId: '1002403',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1002403/pictures/PR1000_PT1002403_2020-08-04-10_05_42_2466908416980392594.jpg',
      },
      {
        pointId: '1002409',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1002409/pictures/PR1000_PT1002409_2020-08-04-13_33_20_8630325655020925733.jpg',
      },
      {
        pointId: '1002431',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1002431/pictures/PR1000_PT1002431_2020-08-13-14_46_16_8207968389861909355.jpg',
      },
      {
        pointId: '1002536',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1002536/pictures/PR1000_PT1002536_2020-09-17-17_24_37_5900700602434587078.jpg',
      },
      {
        pointId: '1002579',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1002579/pictures/PR1000_PT1002579_2021-05-17-10_30_50_2224261547277032175.jpg',
      },
      {
        pointId: '1002597',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1002597/pictures/PR1000_PT1002597_2021-05-17-10_58_47_3416464699811850193.jpg',
      },
      {
        pointId: '1002607',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1002607/pictures/PR1000_PT1002607_2021-05-24-14_37_00_2110261719999007402.jpg',
      },
      {
        pointId: '1002614',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1002614/pictures/PR1000_PT1002614_2021-05-29-09_13_26_6294568437238434940.jpg',
      },
      {
        pointId: '1002635',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1002635/pictures/PR1000_PT1002635_2021-05-31-13_27_30_8053565647727200152.jpg',
      },
      {
        pointId: '1002642',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1002642/pictures/PR1000_PT1002642_2021-06-01-14_49_12_1810854344921926385.jpg',
      },
      {
        pointId: '1002648',
        pictureUrl:
          'https://sprzetassets.pracownikwterenie.pl/zdjecia/projekt_1000/punkt_1002648/pictures/PR1000_PT1002648_2021-06-01-14_53_11_5238935975507517102.jpg',
      },
    ],
  },
});
