export const fixture = () => ({
  projects: [
    { id: '200', title: '== T E S T  ==' },
    { id: '201', title: 'smietnik' },
    { id: '1000', title: '1.Toalety' },
    { id: '1002', title: '2.Kontenery' },
    { id: '1003', title: '3. Toalety usunięte' },
  ],
  users: [
    { id: '1046', login: 'admin1', projects: [] },
    { id: '1048', login: 'admin11', projects: [] },
    { id: '1050', login: 'admin111', projects: [] },
    { id: '1022', login: 'arkadiusz.zawistowski', projects: ['1002'] },
    { id: '1031', login: 'biuro.mobilne', projects: ['1000'] },
    { id: '71', login: 'braciaadmin', projects: [] },
    { id: '1026', login: 'dariusz.opalinski', projects: ['1000'] },
    { id: '1042', login: 'DENYS', projects: [] },
    { id: '1043', login: 'dima', projects: [] },
    { id: '1034', login: 'HUBERT.KLEJNA', projects: ['1000'] },
    { id: '1024', login: 'igor.podzihun', projects: ['1002'] },
    { id: '1023', login: 'jacek.odziemczyk', projects: ['1002'] },
    { id: '1029', login: 'KIEROWCA', projects: ['1000'] },
    { id: '1040', login: 'LUKASZ.BOGUCKI', projects: [] },
    { id: '1014', login: 'lukasz.podgorski', projects: ['1000', '1002'] },
    { id: '1039', login: 'maciej.staniczykowski', projects: [] },
    { id: '1025', login: 'marek.durzynski', projects: ['1000', '1002'] },
    { id: '1012', login: 'mateusz.wroblewski', projects: ['1000', '1002'] },
    { id: '1030', login: 'olga.rebelska', projects: ['1000'] },
    { id: '1009', login: 'pawel.kujawski', projects: ['200', '1000', '1002'] },
    { id: '1018', login: 'piotr.janowski', projects: ['1000'] },
    { id: '1002', login: 'pracownik1', projects: ['1000', '1002'] },
    { id: '1007', login: 'pracownik2', projects: ['1000', '1002'] },
    { id: '1032', login: 'PRZEMEK', projects: ['1000'] },
    { id: '1020', login: 'przemyslaw.salasinski', projects: ['1002'] },
    { id: '1021', login: 'przemyslaw.sobieraj', projects: ['1002'] },
    { id: '1017', login: 'przemyslaw.zygmunt', projects: ['1000'] },
    { id: '1015', login: 'rafal.dzieciol', projects: ['1000'] },
    { id: '1010', login: 'rafal.zolniezak', projects: ['1000', '1002'] },
    { id: '1019', login: 'roman.terechowicz', projects: ['1002'] },
    { id: '1011', login: 'sebastian.czajka', projects: ['1000', '1002'] },
    { id: '1041', login: 'stanislaw.frelek', projects: [] },
    { id: '1027', login: 'SZCZEPAN', projects: [] },
    { id: '1028', login: 'szczepan.goszczynski', projects: ['1000'] },
    {
      id: '1033',
      login: 't1',
      projects: ['200', '201', '1000', '1002', '1003'],
    },
    { id: '1008', login: 'test', projects: ['1002'] },
    { id: '1013', login: 'tomasz.drukalski', projects: ['1000', '1002'] },
    { id: '1016', login: 'waldemar.krzymowski', projects: ['1000'] },
  ],
});
