import LoadingButton from '@mui/lab/LoadingButton';
import { useAppDispatch, useAppSelector } from './hooks';
import { selectAuthToken, setAuthToken, setCurrentUser } from './authSlice';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect } from 'react';
import { FullPageSpinner } from './components/FullPageSpinner';
import { useLazyGetCurrentUserDataQuery } from './netlifyApi';
import { setProjects } from './projectsSlice';
import { useBoolean } from 'react-use';
import { useLocation } from 'react-router-dom';

type LoginPanelProps = {};

const LoginPanel = (props: LoginPanelProps) => {
  const {
    isAuthenticated,
    isLoading,
    error,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  const [flowError, setFlowError] = useBoolean(false);

  const dispatch = useAppDispatch();

  const { pathname } = useLocation();

  const [triggerGetUserData, userDataState] = useLazyGetCurrentUserDataQuery();

  const authToken = useAppSelector(selectAuthToken);

  const triggerLogin = useCallback(
    () =>
      loginWithRedirect({
        appState: {
          returnTo: pathname,
        },
      }),
    [loginWithRedirect, pathname],
  );

  useEffect(() => {
    if (!isAuthenticated && !isLoading && !error) {
      triggerLogin();
    }
  }, [error, isAuthenticated, isLoading, triggerLogin]);

  useEffect(() => {
    (async () => {
      try {
        if (isAuthenticated) {
          const token = await getAccessTokenSilently();
          dispatch(setAuthToken({ token }));
        }
      } catch {
        setFlowError(true);
      }
    })();
  }, [dispatch, getAccessTokenSilently, isAuthenticated, setFlowError]);

  useEffect(() => {
    if (authToken) {
      triggerGetUserData(undefined);
    }
  }, [authToken, triggerGetUserData]);

  useEffect(() => {
    if (userDataState.isSuccess) {
      const { projects, user } = userDataState.data!;
      dispatch(setCurrentUser({ user }));
      dispatch(setProjects({ projects }));
    }
    if (userDataState.isError) {
      setFlowError(true);
    }
  }, [
    dispatch,
    setFlowError,
    userDataState.isSuccess,
    userDataState.isError,
    userDataState.data,
  ]);

  if (error || flowError) {
    return (
      <div>
        <h1>Ups, coś poszło nie tak</h1>
        <LoadingButton loading={isLoading} onClick={triggerLogin}>
          Zaloguj
        </LoadingButton>
      </div>
    );
  }

  return <FullPageSpinner />;
};

export default LoginPanel;
