import { render } from 'react-dom/';
import {
  BrowserRouter as Router,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { store } from './store';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';

import './index.css';
import { App } from './App';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useCallback } from 'react';

const theme = createTheme({
  palette: {
    primary: {
      // main: 'rgba(52, 211, 153)',
      // main: 'rgba(16, 185, 129)',
      main: 'rgba(0,128,128)',
      contrastText: '#fff',
    },
    secondary: {
      main: '#5f082f',
      contrastText: '#fff',
    },
    background: {
      default: 'rgba(236, 253, 245)',
    },
  },
});

const RouterConsumerApp = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onRedirectCallback = useCallback(
    (appState: any) => {
      navigate(appState?.returnTo || pathname, { replace: true });
    },
    [navigate, pathname],
  );

  return (
    <Auth0Provider
      useRefreshTokens
      domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
      redirectUri={`${window.location.origin}/login-success`}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE as string}
      onRedirectCallback={onRedirectCallback}
    >
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <App />
          </LocalizationProvider>
        </ThemeProvider>
      </Provider>
    </Auth0Provider>
  );
};

(async () => {
  if (process.env.NODE_ENV === 'development') {
    const { worker } = require('./mocks/browser');

    await worker.start({
      onUnhandledRequest: ({ url, method }: { url: URL; method: string }) => {
        const ignoredPatterns = [
          /^chrome-extension:/,
          /(maps|fonts)\.(googleapis|gstatic)\.com/,
          /localhost:8888\/(static|icons|.*hot-update\.js|\.json)/,
        ];
        if (!ignoredPatterns.some((pattern) => pattern.test(url.toString()))) {
          // eslint-disable-next-line no-console
          console.warn(`Unhandled ${method} request to ${url}`);
        }
      },
    });
  }

  render(
    <Router>
      <RouterConsumerApp />
    </Router>,
    document.getElementById('root'),
  );
})();
