export const fixture = () => ({
  __meta: { region: 'eu-west-1' },
  data: {
    statuses: [{ id: '1013', name: 'Usunięty - zlikwidowany' }],
    lines: [
      '041FE8F2304C81',
      '04A010DAA64A84',
      '04D5EEF2304C80',
      '048C6A0AC95A81',
      '04E3ECDAA64A80',
      '04C60FDAA64A84',
      '047303DAA64A84',
      '04B2EEF2304C80',
      '0448E7F2304C80',
      '04C810DAA64A84',
      '0437760AC95A81',
      '0432760AC95A81',
      '04AFF6F2304C80',
      '04339AEA076180',
      'r265',
    ],
  },
});
