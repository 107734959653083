export const fixture = () => ({
  employees: [
    { ID: '71', LOGIN: 'braciaadmin', UPR_PANEL: '20' },
    { ID: '1001', LOGIN: 'admin', UPR_PANEL: '99' },
    { ID: '1002', LOGIN: 'pracownik1', UPR_PANEL: '20' },
    { ID: '1007', LOGIN: 'pracownik2', UPR_PANEL: '20' },
    { ID: '1008', LOGIN: 'test', UPR_PANEL: '20' },
    { ID: '1009', LOGIN: 'pawel.kujawski', UPR_PANEL: '20' },
    { ID: '1010', LOGIN: 'rafal.zolniezak', UPR_PANEL: '20' },
    { ID: '1011', LOGIN: 'sebastian.czajka', UPR_PANEL: '20' },
    { ID: '1012', LOGIN: 'mateusz.wroblewski', UPR_PANEL: '20' },
    { ID: '1013', LOGIN: 'tomasz.drukalski', UPR_PANEL: '20' },
    { ID: '1014', LOGIN: 'lukasz.podgorski', UPR_PANEL: '20' },
    { ID: '1015', LOGIN: 'rafal.dzieciol', UPR_PANEL: '20' },
    { ID: '1016', LOGIN: 'waldemar.krzymowski', UPR_PANEL: '20' },
    { ID: '1017', LOGIN: 'przemyslaw.zygmunt', UPR_PANEL: '20' },
    { ID: '1018', LOGIN: 'piotr.janowski', UPR_PANEL: '20' },
    { ID: '1019', LOGIN: 'roman.terechowicz', UPR_PANEL: '20' },
    { ID: '1020', LOGIN: 'przemyslaw.salasinski', UPR_PANEL: '20' },
    { ID: '1021', LOGIN: 'przemyslaw.sobieraj', UPR_PANEL: '20' },
    { ID: '1022', LOGIN: 'arkadiusz.zawistowski', UPR_PANEL: '20' },
    { ID: '1023', LOGIN: 'jacek.odziemczyk', UPR_PANEL: '20' },
    { ID: '1024', LOGIN: 'igor.podzihun', UPR_PANEL: '20' },
    { ID: '1025', LOGIN: 'marek.durzynski', UPR_PANEL: '20' },
    { ID: '1026', LOGIN: 'dariusz.opalinski', UPR_PANEL: '20' },
    { ID: '1027', LOGIN: 'SZCZEPAN', UPR_PANEL: '20' },
    { ID: '1028', LOGIN: 'szczepan.goszczynski', UPR_PANEL: '20' },
    { ID: '1029', LOGIN: 'KIEROWCA', UPR_PANEL: '20' },
    { ID: '1030', LOGIN: 'olga.rebelska', UPR_PANEL: '20' },
    { ID: '1031', LOGIN: 'biuro.mobilne', UPR_PANEL: '20' },
    { ID: '1032', LOGIN: 'PRZEMEK', UPR_PANEL: '20' },
    { ID: '1033', LOGIN: 't1', UPR_PANEL: '20' },
    { ID: '1034', LOGIN: 'HUBERT.KLEJNA', UPR_PANEL: '20' },
    { ID: '1035', LOGIN: 't', UPR_PANEL: '99' },
    { ID: '1039', LOGIN: 'maciej.staniczykowski', UPR_PANEL: '20' },
    { ID: '1040', LOGIN: 'LUKASZ.BOGUCKI', UPR_PANEL: '20' },
    { ID: '1041', LOGIN: 'stanislaw.frelek', UPR_PANEL: '20' },
    { ID: '1042', LOGIN: 'DENYS', UPR_PANEL: '20' },
    { ID: '1043', LOGIN: 'dima', UPR_PANEL: '20' },
    { ID: '1046', LOGIN: 'admin1', UPR_PANEL: '20' },
    { ID: '1048', LOGIN: 'admin11', UPR_PANEL: '20' },
    { ID: '1050', LOGIN: 'admin111', UPR_PANEL: '20' },
  ],
});
