import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from './authSlice';
import { netlifyApi } from './netlifyApi';
import { projectsSlice } from './projectsSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    projects: projectsSlice.reducer,
    [netlifyApi.reducerPath]: netlifyApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(netlifyApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
