const statuses = [
  {
    value: 0,
    description: 'Zablokowany.',
  },
  {
    value: 1,
    description: 'Ograniczony',
  },
  {
    value: 2,
    description: 'Admin',
  },
];
export default statuses;
